.alert-holder {
  position: fixed;
  width: 100%;
  align-content: center;
  top: 0px;
  z-index: 1200;
}
.alert-holder .footer {
  top: 19px;
  width: 100%;
  height: 5px;
  opacity: 0.6;
  position: relative;
  border-radius: 1px;
  background-color: rgba(41, 41, 41, 0.6);
}
.alert {
  padding: 18px 0;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #ed8000;
  border: 1px solid #ed8000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-left: 90px;
  margin-right: 90px;
  margin-top: 0px;
}
.alert,
.alert h4 {
  color: #ed8000;
}
.alert h4 {
  margin: 0;
}
.alert .close {
  position: relative;
  top: -2px;
  right: 15px;
  line-height: 20px;
}
.alert-success {
  background-color: #76b900;
  border-color: #76b900;
  color: #76b900;
}
.alert-success h4 {
  color: #76b900;
}
.alert-danger,
.alert-error {
  background-color: #ee3324;
  border-color: #ee3324;
  color: #ee3324;
}
.alert-danger h4,
.alert-error h4 {
  color: #ee3324;
}
.alert-info {
  background-color: #08a5e1;
  border-color: #08a5e1;
  color: #08a5e1;
}
.alert-info h4 {
  color: #08a5e1;
}
.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}
.alert {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-shadow: none;
}
.alert.alert-xs {
  font-size: 12px;
  line-height: 15px;
  padding: 4px 8px 4px 8px;
  margin: 0px;
}
.alert,
.alert h4 {
  color: #ffffff;
}
.alert .close {
  color: inherit;
  font-family: inherit;
  font-weight: normal;
  opacity: 0.3;
  text-shadow: none;
  -webkit-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}
.alert .close:hover {
  opacity: 1;
}
.alert .btn.pull-right {
  position: relative;
  top: -2px;
}
.alert-fixed-top {
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  left: 15%;
  position: fixed;
  right: 15%;
  top: 0;
}
.animate-repeat {
  list-style: none;
  box-sizing: border-box;
}
.animate-repeat.ng-move,
.animate-repeat.ng-enter,
.animate-repeat.ng-leave {
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
}
.animate-repeat.ng-leave.ng-leave-active,
.animate-repeat.ng-move,
.animate-repeat.ng-enter {
  opacity: 0;
  max-height: 0;
}
.animate-repeat.ng-leave,
.animate-repeat.ng-move.ng-move-active,
.animate-repeat.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 50px;
}
.table th {
  background-color: #cd5763 !important;
  color: #ffffff;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condense > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 4px;
}
.table-condensed-xs > thead > tr > th,
.table-condensed-xs > tbody > tr > th,
.table-condensed-xs > tfoot > tr > th,
.table-condensed-xs > thead > tr > td,
.table-condensed-xs > tbody > tr > td,
.table-condensed-xs > tfoot > tr > td {
  padding: 1px;
}
.table-condensed-xs > thead > tr > th {
  background-color: #cd5763 !important;
}
.table-condensed-xs {
  margin-bottom: 0 !important;
}
.table > tbody > tr.error-row {
  background-color: #f5857c;
}
.table .loading-table {
  min-width: 100%;
  height: 100px;
  background-color: #d7d7d7;
  text-align: center;
}
/*============================================================================================================================*/
.modal-below-header .modal-content {
  margin-top: 150px;
}
.opacity-7 {
  opacity: 0.7;
}
.screen-display-menu i.cached {
  color: #ee3324;
}
.screen-display-menu i.loading_data {
  color: #ee3324;
  animation: blinker 1s linear infinite;
}
.menu-bars {
  float: left;
  overflow: visible;
  margin: 0;
  width: 50px;
  height: 55px;
  padding: 18px 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
}
.menu-bars .menu-bars-box {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 18px;
}
.menu-bars .menu-bars-box .menu-bars-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
}
.menu-bars .menu-bars-box .menu-bars-inner,
.menu-bars .menu-bars-box .menu-bars-inner:before,
.menu-bars .menu-bars-box .menu-bars-inner:after {
  position: absolute;
  width: 2rem;
  height: 0.2rem;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
}
.menu-bars .menu-bars-box .menu-bars-inner:after,
.menu-bars .menu-bars-box .menu-bars-inner:before {
  display: block;
  content: "";
}
.menu-bars .menu-bars-box .menu-bars-inner:before {
  top: -10px;
}
.menu-bars .menu-bars-box .menu-bars-inner:after {
  bottom: -10px;
}
.menu-bars.menu-bars--spring .menu-bars-inner {
  top: 2px;
  transition: background-color 0s linear 0.13s;
}
.menu-bars.menu-bars--spring .menu-bars-inner:before {
  top: 8px;
  transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.menu-bars.menu-bars--spring .menu-bars-inner:after {
  top: 16px;
  transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.menu-bars.menu-bars--spring.is-active .menu-bars-inner {
  transition-delay: .22s;
  background-color: transparent!important;
}
.menu-bars.menu-bars--spring.is-active .menu-bars-inner:before {
  top: 0;
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.menu-bars.menu-bars--spring.is-active .menu-bars-inner:after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}
.logo-text {
  height: 44px;
  float: left;
  line-height: 15px;
  margin: 8px 0px 0px 14px;
}
.logo-beta-tag {
  width: 2px;
  height: 55px;
  background-color: #f20b7f;
  float: right;
}
.logo-slide-out {
  width: 177px;
  position: absolute;
  height: 55px;
  top: 0px;
  left: -90px;
  background: url(assets/img/logo-details-bg.png);
  z-index: 790;
  color: #b6abb0;
  font-size: 14px;
  text-align: center;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  /** Chrome & Safari **/
  -moz-transition: all 1s ease-in-out;
  /** Firefox **/
  -o-transition: all 1s ease-in-out;
  /** Opera **/
}
.header {
  width: 100%;
  background-color: #ffcb52;
}
.nav-open .main-panel {
  margin-left: 94px;
}
.nav-open .nav-panel {
  float: left;
}
.nav-closed .main-panel {
  margin-left: 6px;
}
.nav-closed .nav-panel {
  left: -88px;
  margin: 0 ;
}
.nav-closed .nav-panel:hover {
  left: 0;
  margin: 0 ;
  z-index: 1102 ;
}
.fixed-header .nav-panel {
  position: fixed;
  z-index: 780;
}
.fixed-header .header {
  position: fixed;
  z-index: 780;
}
.header {
  height: 55px;
}
.header {
  /*background: transparent url("@{componentsPath}/brandkit/img/ge-monogram-athensgray.svg") no-repeat;*/
}
.header .gt-badge {
  display: inline-block;
  min-width: 10px;
  padding: 2px 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #777;
  border-radius: 5px;
}
.header li .gt-badge {
  margin-left: 3px;
}
.header .gt-badge :empty {
  display: none;
}
.header .badge-xs {
  top: 0;
  padding: 1px 5px;
}
.header .badge-green {
  background-color: #33cc33;
}
.header .badge-pink {
  background-color: #f20b7f;
}
.header .badge-red {
  background-color: #ee3324;
}
.header .badge-blue {
  background-color: #1ca5cf;
}
.header .badge-orange {
  background-color: #ed8000;
}
.header a.badge:hover,
.header a.badge:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.header .header-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
}
.header .header-container {
  /* Notification Icon styles */
  /* Notification Icon styles */
}
.header .header-container .logo {
  height: 55px;
  width: 80px;
  margin-left: 50px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 800;
  transition: width .5s ease;
}
.header .header-container .logo-open {
  width: 170px;
}
.header .header-container .app-badge {
  position: absolute;
  left: 130px;
  max-width: 55px;
  text-align: center;
  padding: 17px 13px;
  transition: left .5s ease;
}
.header .header-container .app-badge-open {
  left: 220px;
}
.header .header-container .gt-title-cnt {
  float: left;
  left: 145px;
  height: 54px;
  font-size: 14px;
  overflow: hidden;
  color: #b6abb0;
  line-height: 55px;
  position: relative;
  list-style-type: none;
  width: calc(100% - 950px);
  transition: left .5s ease;
}
.header .header-container .gt-title-cnt .title {
  font-size: 15px;
  height: 20px;
  color: #FFFFFF;
  margin-top: 8px;
}
.header .header-container .gt-title-cnt .gt-breadcrumb {
  font-size: 14px;
  list-style-type: none;
  margin: 0 0 0 0px;
}
.header .header-container .gt-title-cnt .gt-breadcrumb ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header .header-container .gt-title-cnt .gt-breadcrumb ul li {
  display: inline;
}
.header .header-container .gt-title-cnt .gt-breadcrumb ul li i {
  font-size: 11px;
  margin: 0px 2px 0px 2px;
}
@media screen and (max-width: 1200px) {
  .header .header-container .gt-title-cnt {
    width: auto;
  }
}
.header .header-container .gt-title-cnt-open {
  left: 235px;
}
.header .header-container .gt-breadcrumb-sm {
  display: none;
}
.header .header-container .gt-user-drop {
  width: 172px;
  height: 55px;
  background: #2b2b2b;
  float: right;
  color: #d7d7d7;
  font-size: 12px;
  cursor: pointer;
}
.header .header-container .gt-user-drop-toggle {
  width: 172px;
  height: 55px;
}
.header .header-container .gt-user-drop:hover {
  background: #0d0d0d;
}
.header .header-container .gt-user-drop i {
  font-size: 14px;
  line-height: 55px;
  margin-left: 5px;
}
.header .header-container .gt-user-drop .gt-display-name {
  width: 75px;
  margin-top: 9px;
  overflow: hidden;
  line-height: 15px;
  margin-left: 10px;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}
.header .header-container .gt-user-drop .gt-avatar img {
  height: 40px;
  width: 40px;
  margin-top: 5px !important;
  margin-right: 12px !important;
}
.header .header-container .gt-user-drop .gt-avatar svg circle {
  fill: #cd5763;
}
.header .header-container .gt-user-drop .header-dropdown-menu li:hover a {
  color: #000;
}
.header .header-container .gt-user-drop .header-dropdown-menu .dropdown-label {
  font-size: 13px;
  padding: 0 10px;
  cursor: default;
  font-weight: bold;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.header .header-container .gt-user-drop .header-dropdown-menu .header-links {
  display: none;
}
.header .header-container .gt-header-search {
  height: 55px;
  width: 44px;
  text-align: center;
  cursor: pointer;
}
.header .header-container .gt-header-search i {
  font-size: 16px;
  line-height: 55px;
}
.header .header-container .gt-header-nav {
  margin: 0 10px 0 10px;
  padding: 0;
}
.header .header-container .gt-header-nav li {
  display: inline-block;
  list-style: none;
  line-height: 55px;
  margin-left: 15px;
  height: 55px;
  cursor: pointer;
}
.header .header-container .gt-header-nav li a {
  text-decoration: none;
}
.header .header-container .gt-header-nav li a:hover {
  color: #29c7f7;
}
.header .header-container .gt-header-nav li:hover {
  border-bottom: 2px solid #29c7f7;
}
.header .header-container .gt-header-nav li.active {
  border-bottom: 2px solid #29c7f7;
}
.header .header-container .gt-header-nav li.active a {
  color: #29c7f7;
}
@media screen and (max-width: 1200px) {
  .header .header-container .gt-breadcrumb ul {
    display: none;
  }
  .header .header-container .gt-header-nav {
    display: none;
  }
  .header .header-container .gt-breadcrumb-sm {
    display: block;
  }
  .header .header-container .gt-user-drop .header-dropdown-menu .header-links {
    display: block;
  }
  .header .header-container .gt-user-drop .header-dropdown-menu .header-links .dropdown-label:hover {
    background-color: #333;
    color: #dfdfdf;
  }
  .header .header-container .gt-user-drop .header-dropdown-menu .header-links li {
    padding: 1px 15px 1px 20px;
  }
  .header .header-container .gt-user-drop .header-dropdown-menu .header-links :hover {
    color: #000;
    background-color: #f5f5f5;
  }
  .header .header-container .gt-user-drop .header-dropdown-menu .header-links .gt-badge {
    float: right;
  }
  .header .header-container .gt-user-drop .header-dropdown-menu .header-links :hover {
    text-decoration: none;
  }
}
.header .header-container .gt-global-notifications {
  width: 44px;
  cursor: pointer;
  text-align: center;
  position: relative;
  height: 55px;
  border-right: 1px solid #000;
}
.header .header-container .gt-global-notifications {
  /* Safari 4.0 - 8.0 */
  /* Standard syntax */
}
.header .header-container .gt-global-notifications i {
  font-size: 16px;
  color: #d7d7d7;
  line-height: 55px;
}
.header .header-container .gt-global-notifications span.gt-badge {
  top: 10px;
  right: 5px;
  position: absolute;
}
.header .header-container .gt-global-notifications .notification-loader {
  top: 52px;
  width: 35%;
  height: 2px;
  position: absolute;
  animation-duration: 2s;
  background-color: #ed8000;
  animation-name: side-to-side;
  -webkit-animation-duration: 2s;
  /* Safari 4.0 - 8.0 */
  animation-iteration-count: infinite;
  -webkit-animation-name: side-to-side;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  /* Safari 4.0 - 8.0 */
}
@-webkit-keyframes side-to-side {
  0% {
    left: 0;
  }
  50% {
    left: 65%;
  }
  100% {
    left: 0;
  }
}
@keyframes side-to-side {
  0% {
    left: 0;
  }
  50% {
    left: 65%;
  }
  100% {
    left: 0;
  }
}
.header .header-container .gt-global-notifications + .popover .popover-content {
  padding: 0;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notification-header {
  width: 100%;
  padding: 10px;
  background-color: #d6d6d6;
  -webkit-box-shadow: 0px 3px 5px 0px #cccccc;
  -moz-box-shadow: 0px 3px 5px 0px #cccccc;
  box-shadow: 0px 3px 5px 0px #cccccc;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notification-header .info-container {
  font-size: 20px;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notification-header .title {
  text-align: center;
  font-weight: bold;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notifications-list {
  padding: 10px;
  max-width: 300px;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notifications-list .notfication-item {
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notifications-list .notfication-item .notification-message {
  display: inline-block;
  width: 92%;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notifications-list .notfication-item .dot-container {
  display: inline-block;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notifications-list .notfication-item span.success {
  background-color: #33cc33;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notifications-list .notfication-item span.error {
  background-color: #ee3324;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notifications-list .notfication-item span.info {
  background-color: #08a5e1;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notifications-list .notfication-item span.dot {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 6px;
}
.header .header-container .gt-global-notifications + .popover .popover-content .gt-notifications-list .notfication-item:before {
  content: '';
  height: 100%;
  border-right: 1px solid #ccc;
  position: absolute;
  left: 5.5px;
  z-index: -1;
}
.header .gt-global-search {
  position: relative;
}
.header .gt-global-search .search-box {
  width: 400px;
  height: 55px;
  position: absolute;
  top: 0px;
  right: 55px;
  background-color: #000000;
  padding: 0px 10px 0px 10px;
}
.header .gt-global-search .search-box .input-group {
  line-height: 44px;
}
.header .gt-global-search .search-box .input-group input {
  margin-top: 7px;
}
.nav-open .main-panel {
  margin-left: 94px;
}
.nav-open .nav-panel {
  float: left;
}
.nav-open .visible-nav-closed {
  display: none;
}
.default-dropdowns {
  cursor: pointer;
  color: #ffffff;
  overflow: hidden;
  padding: 6px 10px;
  margin-bottom: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.no-hand-pointer {
  cursor: default;
}
.issue-scrollbar {
  float: left;
  height: 300px;
  width: 65px;
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
}
#issue-scrollbar-style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
#issue-scrollbar-style-1::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}
#issue-scrollbar-style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.btn-group.tax-year {
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
}
.btn-group.tax-year button:first-child {
  color: #FFFFFF;
  text-align: left;
  width: calc(100% - 25px);
  background-color: #5d5f5f;
  text-overflow: ellipsis;
  overflow: hidden;
}
.btn-group.tax-year button:nth-child(2) {
  border: 1px solid #f5857c;
}
.btn-group.tax-year .dropdown-menu {
  left: 6px;
  margin-top: -3px;
}
.btn-group.tax-year .dropdown-menu > li > a:hover {
  background-color: #e6e6e6;
}
.nav-closed .main-panel {
  margin-left: 50px !important;
}
.nav-closed .nav-panel {
  left: 0 !important;
  margin: 0;
  width: 94px;
  overflow-x: hidden;
}
.nav-closed .hidden-nav-closed {
  display: none;
}
.nav-closed .visible-nav-closed {
  display: block;
  text-align: center;
}
.left-nav-selected-Me {
  padding: 0 6px 6px 6px;
}
.left-nav-selected-Me .gt-hierarchy-drop-down .drop-down-btn {
  height: 22px;
  color: #fff;
  cursor: pointer;
  padding-right: 0;
  overflow: hidden;
  line-height: 24px;
  width: 100% !important;
  background-color: #5d5f5f;
}
.left-nav-selected-Me .gt-hierarchy-drop-down .drop-down-btn i {
  width: 26px;
  text-align: center;
  background-color: #f5857c;
  border-radius: 0px 3px 3px 0px;
}
.left-nav-selected-Me .gt-hierarchy-drop-down .drop-down-btn .badge {
  margin: 3px 7px 0px 0px;
}
.left-nav-selected-Me .gt-hierarchy-drop-down .gt-drop-container .left-menu {
  width: 210px;
  margin-top: 3px;
  border-radius: 5px;
  background: #313840;
  border: 1px solid #000;
  box-shadow: 2px 3px 5px #1d1d1d;
}
.left-nav-selected-Me .gt-hierarchy-drop-down .gt-drop-container .left-menu li {
  color: #dfdfdf;
  background-color: #485058;
}
.left-nav-selected-Me .gt-hierarchy-drop-down .gt-drop-container .left-menu .unselect {
  border-bottom: 1px solid #232323;
}
.nav-panel {
  display: block;
  width: 94px;
  font-family: 'PwC Helvetica Neue', sans-serif !important;
  min-height: 100%;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  -ms-transition: all .5s ease;
  transition: all .5s ease;
}
.nav-panel .client-ellip {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.nav-panel .client-selection {
  color: #FFFFFF;
  height: 30px;
  line-height: 30px;
  margin-top: 1px;
  padding-right: 9px;
}
.nav-panel .client-selection .client-selection-dropdown {
  width: 155px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
}
.nav-panel .client-selection img {
  margin-left: 10px;
  margin-right: 5px;
  margin-bottom: 4px;
  max-width: 31px;
  min-width: 31px;
}
.nav-panel .nav-header {
  height: 30px;
  margin-bottom: 1px;
  margin-top: 1px;
}
.nav-panel .nav-header.search-wrapper {
  overflow: hidden;
}
.nav-panel .nav-header .tax-year {
  margin: 5px 0px 0px 10px;
}
.nav-panel .nav-header .menu-expand i {
  color: #5d5f5f !important;
  font-size: 14px !important;
  margin: 8px 5px 0px 0px;
  cursor: pointer;
}
.nav-panel .nav-header .menu-expand i:hover {
  color: #f5857c !important;
}
.nav-panel .nav-header .toggle-nav {
  height: 30px;
  background-color: #4c4f4f;
  width: 30px;
  text-align: center;
  line-height: 30px;
  float: right;
  cursor: pointer;
}
.nav-panel .nav-header .toggle-nav:hover {
  background-color: #f5857c;
}
.nav-panel .nav-header i {
  font-size: 16px;
}
div[gt-menu-search].search-input,
div[gt-select-menu-search].search-input {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  height: 22px;
  width: 170px;
  float: left;
  margin: 4px 0px 0px 8px;
  transition: background-color 0.5s ease;
  color: #383436;
}
div[gt-menu-search].search-input input,
div[gt-select-menu-search].search-input input {
  height: 22px;
  background-color: transparent;
  border: none;
  float: left;
  outline: none;
  margin-left: 5px;
  width: 130px;
}
div[gt-menu-search].search-input input::-webkit-input-placeholder,
div[gt-select-menu-search].search-input input::-webkit-input-placeholder,
div[gt-menu-search].search-input input::-moz-placeholder,
div[gt-select-menu-search].search-input input::-moz-placeholder,
div[gt-menu-search].search-input input::-moz-placeholder,
div[gt-select-menu-search].search-input input::-moz-placeholder,
div[gt-menu-search].search-input input::-ms-input-placeholder,
div[gt-select-menu-search].search-input input::-ms-input-placeholder {
  color: #4c4f4f;
}
div[gt-menu-search].search-input .close-x,
div[gt-select-menu-search].search-input .close-x {
  font-size: 12px;
  float: right;
  line-height: 22px;
  margin-right: 5px;
  cursor: pointer;
}
div[gt-menu-search].search-input .search,
div[gt-select-menu-search].search-input .search {
  font-size: 14px;
  float: left;
  line-height: 22px;
  margin-left: 5px;
  cursor: pointer;
}
div[gt-menu-search].search-input:hover input::-webkit-input-placeholder,
div[gt-select-menu-search].search-input:hover input::-webkit-input-placeholder {
  color: #a0a5a5;
}
div[gt-menu-search].search-input:hover input::-moz-placeholder,
div[gt-select-menu-search].search-input:hover input::-moz-placeholder {
  color: #a0a5a5;
}
div[gt-menu-search].search-input:hover input::-moz-placeholder,
div[gt-select-menu-search].search-input:hover input::-moz-placeholder {
  color: #a0a5a5;
}
div[gt-menu-search].search-input:hover input::-ms-input-placeholder,
div[gt-select-menu-search].search-input:hover input::-ms-input-placeholder {
  color: #a0a5a5;
}
div[data-gt-menu]:not([gt-user-assign-menu]),
div[gt-select-menu-tree]:not([gt-user-assign-menu]) {
  width: 209px;
}
div[data-gt-menu] ul,
div[gt-select-menu-tree] ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
}
div[data-gt-menu] > ul,
div[gt-select-menu-tree] > ul {
  font-size: 13px;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div[data-gt-menu] ul li,
div[gt-select-menu-tree] ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
}
div[data-gt-menu] ul li div:first-child,
div[gt-select-menu-tree] ul li div:first-child {
  padding-left: 5px;
}
div[data-gt-menu] ul li div:first-child:before,
div[gt-select-menu-tree] ul li div:first-child:before,
div[data-gt-menu] ul li div:first-child:after,
div[gt-select-menu-tree] ul li div:first-child:after {
  clear: both;
  content: '';
  display: table;
}
div[data-gt-menu] ul li div i,
div[gt-select-menu-tree] ul li div i {
  padding-right: 5px;
}
div[data-gt-menu] ul li div i.add-btn,
div[gt-select-menu-tree] ul li div i.add-btn {
  color: #33cc33;
}
div[data-gt-menu] ul li div i.remove-btn,
div[gt-select-menu-tree] ul li div i.remove-btn {
  color: #ee3324;
}
div[data-gt-menu] ul li div.activity,
div[gt-select-menu-tree] ul li div.activity {
  list-style: none;
  height: 20px;
  line-height: 20px;
  margin-bottom: 1px;
  font-size: 12px;
}
div[data-gt-menu] ul li div.activity:before,
div[gt-select-menu-tree] ul li div.activity:before,
div[data-gt-menu] ul li div.activity:after,
div[gt-select-menu-tree] ul li div.activity:after {
  clear: both;
  display: table;
  content: '';
}
div[data-gt-menu] ul li div.activity input[type=radio],
div[gt-select-menu-tree] ul li div.activity input[type=radio] {
  margin-top: 0;
  margin-right: 2px;
  vertical-align: middle;
}
div[data-gt-menu] ul > li[class*="level-"] > div:first-child,
div[gt-select-menu-tree] ul > li[class*="level-"] > div:first-child {
  list-style: none;
  height: 30px;
  line-height: 30px;
  margin-bottom: 1px;
  overflow: hidden;
}
div[data-gt-menu] ul > li[class*="level-"] > div:first-child.screen:not(.screen-space),
div[gt-select-menu-tree] ul > li[class*="level-"] > div:first-child.screen:not(.screen-space) {
  height: 20px;
  background-color: transparent;
  line-height: 20px;
  font-size: 12px;
}
div[data-gt-menu] ul > li.level-7 > div:first-child,
div[gt-select-menu-tree] ul > li.level-7 > div:first-child {
  background-color: extract(#363234, #423d40, #50494d, #5d5659, #696969, #787878, #888888, 8);
  margin-left: 35px;
}
div[data-gt-menu] ul > li.level-7 > div:first-child.screen,
div[gt-select-menu-tree] ul > li.level-7 > div:first-child.screen {
  padding-left: 34px;
}
div[data-gt-menu] ul > li.level-6 > div:first-child,
div[gt-select-menu-tree] ul > li.level-6 > div:first-child {
  background-color: #888888;
  margin-left: 30px;
}
div[data-gt-menu] ul > li.level-6 > div:first-child.screen,
div[gt-select-menu-tree] ul > li.level-6 > div:first-child.screen {
  padding-left: 34px;
}
div[data-gt-menu] ul > li.level-5 > div:first-child,
div[gt-select-menu-tree] ul > li.level-5 > div:first-child {
  background-color: #787878;
  margin-left: 25px;
}
div[data-gt-menu] ul > li.level-5 > div:first-child.screen,
div[gt-select-menu-tree] ul > li.level-5 > div:first-child.screen {
  padding-left: 29px;
}
div[data-gt-menu] ul > li.level-4 > div:first-child,
div[gt-select-menu-tree] ul > li.level-4 > div:first-child {
  background-color: #696969;
  margin-left: 20px;
}
div[data-gt-menu] ul > li.level-4 > div:first-child.screen,
div[gt-select-menu-tree] ul > li.level-4 > div:first-child.screen {
  padding-left: 24px;
}
div[data-gt-menu] ul > li.level-3 > div:first-child,
div[gt-select-menu-tree] ul > li.level-3 > div:first-child {
  background-color: #5d5659;
  margin-left: 15px;
}
div[data-gt-menu] ul > li.level-3 > div:first-child.screen,
div[gt-select-menu-tree] ul > li.level-3 > div:first-child.screen {
  padding-left: 19px;
}
div[data-gt-menu] ul > li.level-2 > div:first-child,
div[gt-select-menu-tree] ul > li.level-2 > div:first-child {
  background-color: #50494d;
  margin-left: 10px;
}
div[data-gt-menu] ul > li.level-2 > div:first-child.screen,
div[gt-select-menu-tree] ul > li.level-2 > div:first-child.screen {
  padding-left: 14px;
}
div[data-gt-menu] ul > li.level-1 > div:first-child,
div[gt-select-menu-tree] ul > li.level-1 > div:first-child {
  background-color: #423d40;
  margin-left: 5px;
}
div[data-gt-menu] ul > li.level-1 > div:first-child.screen,
div[gt-select-menu-tree] ul > li.level-1 > div:first-child.screen {
  padding-left: 9px;
}
div[data-gt-menu] ul > li.level-0 > div:first-child,
div[gt-select-menu-tree] ul > li.level-0 > div:first-child {
  background-color: #363234;
  margin-left: 0px;
}
div[data-gt-menu] ul > li.level-0 > div:first-child.screen,
div[gt-select-menu-tree] ul > li.level-0 > div:first-child.screen {
  padding-left: 4px;
}
div[gt-menu-tree] li[class*="level-"] > div:first-child {
  font-size: 13px;
}
div[gt-menu-tree] ul li div .circle {
  background-color: #ea6358;
  border: 2px solid #124656;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  margin: 4px;
}
div[gt-select-menu-tree] {
  min-width: 96%;
}
div[gt-select-menu-tree] div[gt-select-menu-tree] {
  width: 100%;
}
.badge-red {
  background-color: #ee3324;
}
.dropdown-menu li {
  font-size: 14px;
}
.dropdown-menu li.divider {
  margin: 1px 0 1px 0;
}
.gt-tab-container {
  top: 55px;
  z-index: 779;
  width: 100%;
  height: 32px;
  font-size: 12px;
  overflow: visible;
  white-space: nowrap;
  position: sticky;
  background-color: #b9b6b6;
  border-bottom: 1px solid #938686;
}
.gt-menu-tools {
  float: left;
}
.gt-menu-scroll {
  top: 0px;
  left: 0px;
  float: left;
}
.gt-menu-scroll > li,
.gt-menu-tools > li,
.gt-tab-container > li {
  float: left;
  line-height: 30px;
  border-right: 1px solid #938686;
  border-top: 1px solid #938686;
  padding-left: 10px;
  padding-right: 10px;
  height: 31px;
  color: #000000;
  list-style: none;
  cursor: pointer;
  white-space: nowrap;
}
.gt-menu-scroll > li li,
.gt-menu-tools > li li,
.gt-tab-container > li li {
  font-size: 11px;
}
.gt-menu-scroll > li li.divider,
.gt-menu-tools > li li.divider,
.gt-tab-container > li li.divider {
  margin: 1px 0 1px 0;
}
.gt-menu-scroll > li i,
.gt-menu-tools > li i,
.gt-tab-container > li i {
  margin-left: 5px;
}
.gt-menu-scroll > li.menu,
.gt-menu-tools > li.menu,
.gt-tab-container > li.menu {
  color: #0d0d0d;
  font-size: 14px;
  padding: 0px 3px 0 3px;
}
.gt-menu-scroll > li.menu i,
.gt-menu-tools > li.menu i,
.gt-tab-container > li.menu i {
  margin-left: 5px;
  margin-right: 5px;
}
.gt-menu-scroll > li:hover,
.gt-menu-tools > li:hover,
.gt-tab-container > li:hover,
.gt-menu-scroll li.active,
.gt-menu-tools li.active,
.gt-tab-container li.active {
  background-color: #ffffff;
  color: #0d0d0d;
}
.gt-menu-scroll i.cached,
.gt-menu-tools i.cached,
.gt-tab-container i.cached {
  color: #ee3324;
}
.gt-menu-scroll i.loading_data,
.gt-menu-tools i.loading_data,
.gt-tab-container i.loading_data {
  color: #ee3324;
  animation: blinker 1s linear infinite;
}
.gt-menu-scroll .inherit-link,
.gt-menu-tools .inherit-link,
.gt-tab-container .inherit-link {
  position: absolute;
  top: 1px;
  left: 1px;
}
.gt-menu-scroll i.link,
.gt-menu-tools i.link,
.gt-tab-container i.link {
  margin: 0px;
  font-size: 11px;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.voice-brand {
  font-family: 'PwC Helvetica Neue', sans-serif;
}
.voice-data {
  font-family: '';
}
.voice-human {
  font-family: '';
}
.voice-ui {
  font-family: '';
}
.color-black {
  color: #13161a;
}
.color-green {
  color: #33cc33;
}
.color-cyan {
  color: #08a5e1;
}
.color-orange {
  color: #ed8000;
}
.color-red {
  color: #ee3324;
}
.color-purple {
  color: #711371;
}
.color-white {
  color: #ffffff;
}
.color-background-black {
  background-color: #13161a !important;
}
.color-background-gray-dark {
  background-color: #4c4f4f !important;
}
.color-background-gray {
  background-color: #2b2b2b !important;
}
.color-background-gray-lighter {
  background-color: #d7d7d7 !important;
}
.color-background-green {
  background-color: #33cc33 !important;
}
.color-background-cyan {
  background-color: #08a5e1 !important;
}
.color-background-orange {
  background-color: #ed8000 !important;
}
.color-background-red {
  background-color: #ee3324 !important;
}
.color-background-red-light {
  background-color: #f5857c !important;
}
.color-background-purple {
  background-color: #711371 !important;
}
.color-background-white {
  background-color: #ffffff !important;
}
.ge-logo {
  background: transparent url(assets/img/ge-monogram-athensgray.svg) no-repeat;
  background-size: contain;
  border: 0;
  color: transparent;
  float: left;
  font: 0/0 a;
  height: 38px;
  margin: -8px 9px -10px -3px;
  width: 37px;
}
html {
  font-size: 14px !important;
}
html,
body,
#body2 {
  height: 100%;
  min-height: 100%;
  font-family: 'PwC Helvetica Neue', sans-serif !important;
}
html.hidden-menu-mobile-lock {
  overflow-x: hidden;
}
.fade-light {
  opacity: 0.5;
}
.gt-body-container {
  padding-top: 68px;
  min-height: 100%;
  transition: margin-top 0.5s ease;
}
.row-gray-callOut {
  background-color: #cdc9c8;
  padding: 10px 0px 10px 0px;
  margin: 0px 0px 10px 0px;
}
.gg-hidden-paste-area {
  height: 0px;
  overflow: hidden;
  border: none;
}
.form-error-container {
  display: block ;
  padding: 5px 15px 5px 15px;
  margin: 5px 0 0 0  ;
  background-color: #ee3324;
  color: #ffffff;
  -webkit-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
}
.gt-info-container {
  overflow: hidden;
  height: auto;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  background-color: rgba(173, 27, 2, 0.5);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 900px;
  margin-left: -450px;
  margin-top: -100px;
  -webkit-box-shadow: 1px 1px 0px 0px #333333;
  box-shadow: 1px 1px 0px 0px #333333;
}
.gt-info-container .logo-container {
  float: left;
  -webkit-border-radius: 5px 0px 0px 5px;
  border-radius: 5px 0px 0px 5px;
  padding: 10px 10px 10px 10px !important;
  border-right: 1px solid #107ea8;
}
.gt-info-container .message-container {
  color: #ffffff;
  padding: 0px;
  margin: 20px 20px 20px 100px;
}
.gt-info-container .message-container h4 {
  padding: 0px;
  margin: 0px;
}
.gt-info-container .message-container a {
  color: #ffffff;
  text-decoration: underline;
}
.main-panel {
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  -ms-transition: all .5s ease;
  transition: all .5s ease;
}
.gt-background-blur {
  background-color: #f2f2f2;
  margin: 0 !important;
  padding: 0 !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.gt-page-body {
  margin: 10px;
  background-color: #ffffff;
  min-height: 500px;
  border: 1px solid #b9b4b4;
  -webkit-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
}
.gt-page-body-dark {
  margin: 10px;
}
.holder-404 {
  text-align: center;
}
.holder-404 img {
  margin: 50px 10px 0 10px;
}
.gt-page-header {
  height: 42px;
}
.gt-page-header h1 {
  font-size: 18px;
  line-height: 38px;
  margin: 0 10px 0 10px;
  padding: 0px;
}
.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
}
@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash 3.4s ease-in-out infinite;
  animation: dash 3.4s ease-in-out infinite;
  stroke: #1ca5cf;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}
[ng-cloak].loading {
  display: block !important;
  height: 100%;
  width: 100%;
}
[ng-cloak].loading .gt-info-container {
  display: block !important;
}
[ng-cloak].loading .gt-large-loader {
  display: block !important;
}
.gt-large-loader {
  display: none;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -85px;
  margin-top: -85px;
  -webkit-box-shadow: 1px 1px 0px 0px #333333;
  box-shadow: 1px 1px 0px 0px #333333;
}
.gt-large-loader .logo {
  position: absolute;
  top: 35px;
  left: 38px;
  right: 0;
  bottom: 0;
}
.gt-large-loader .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.gt-large-loader .text {
  position: absolute;
  top: 170px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 170px;
  font-size: 15px;
  font-weight: bold;
}
.loading {
  width: 0%;
  position: fixed;
  z-index: 1300;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.loading .progress {
  -webkit-border-radius: 10px !important;
  border-radius: 10px !important;
  -webkit-box-shadow: 0 0 3px 0 #453434;
  box-shadow: 0 0 3px 0 #453434;
}
.loading .gt-info-container {
  display: none;
}
.loading .loading-text i {
  font-size: 44px ;
}
.loading h2 {
  font-size: 1.1em;
  font-weight: 500;
}
.gt-edit-col {
  width: 27px;
}
.gt-edit-col i {
  cursor: pointer;
}
.gg-row-alt {
  padding: 5px;
  border-bottom: dotted 1px #cccccc;
}
.gg-row-alt:nth-child(even) {
  background: #eeeeee;
}
/*Messagebox */
.div-message-box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
}
/*
.MessageIE{
    background-image: url("@{base-url}/bg.png");

}*/
.message-box-container {
  top: 55%;
  color: white;
  position: absolute;
  width: 100% !important;
  background-color: #232323;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0px 10px;
}
.message-box-middle {
  position: relative;
  left: 20%;
  width: 60%;
  padding: 20px 0px;
}
.message-box-middle .msg-title {
  letter-spacing: -1px;
  font-size: 24px;
  font-weight: 300;
}
.message-box-middle .p-text {
  font-style: 30px;
}
.message-box-container input {
  width: 50%;
  padding: 5px;
}
.message-box-container select {
  width: 50%;
  padding: 5px;
}
.message-box-button-section {
  width: 100%;
  height: 30px;
}
.message-box-button-section button {
  float: right;
  margin-right: 7px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: bold;
}
/* End of MessageBox */
.gt-filter-container {
  height: 42px;
  overflow: visible;
  display: flex;
  padding: 4px;
}
.gt-filter-container .col {
  align-self: center ;
  margin: 0px 0px 0px 5px;
}
.gt-filter-container i.gt-refresh {
  font-size: 22px;
  cursor: pointer;
  margin-right: 5px;
  float: left;
}
.gt-filter-container .input-group {
  float: left;
}
.gt-filter-container .input-group > input {
  height: 25px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: #cbc8c8;
}
.gt-filter-container .input-group input:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.gt-filter-container > input,
.gt-filter-container .gt-filter-loading,
.gt-filter-container select,
.gt-filter-container .input-group > input {
  background-color: #4c4f4f;
  border: 1px solid #4c4f4f;
  height: 25px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  color: #cbc8c8;
}
.gt-filter-container .gt-filter-loading {
  min-width: 100px;
  font-size: 16px;
  padding: 0px;
  position: relative;
}
.gt-filter-container .gt-filter-loading i {
  position: absolute;
  left: 50%;
  margin-left: -10px;
  top: 3px;
}
.gt-filter-container .input-group-addon {
  font-size: 11px;
  padding: 0px 6px 0px 6px;
  background-color: #000000;
  border: 1px solid #4c4f4f;
}
.gt-filter-container .dropdown-multiselect .btn {
  background-color: #4c4f4f;
  border: 1px solid #4c4f4f;
  height: 25px;
  padding: 5px 10px;
  font-size: 12px;
  color: #cbc8c8;
}
.gt-http-request-failed {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(110, 98, 95, 0.5);
  z-index: 999;
  opacity: 0.9;
  display: none;
}
.gt-http-request-failed-action {
  z-index: 9999;
  background-color: #ad1b02;
}
.editDisabled a {
  pointer-events: none;
  cursor: not-allowed;
  color: gray ;
}
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #ffffff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}
.ripple:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}
[ng-cloak] .loading .message-container {
  animation: 0.2s lightSpeedOut ease;
}
.gt-global-search .ng-hide-add {
  animation: 0.2s lightSpeedOut ease;
}
/* when showing the picture */
.gt-global-search .ng-hide-remove {
  animation: 0.5s flipInX ease;
}
.gt-drop-container .ng-hide-add {
  animation: 0.2s display-none-transition ease;
}
/* when showing the picture */
.gt-drop-container .ng-hide-remove {
  animation: 0.5s display-none-transition ease;
}
/*
.gt-dashboard  .ng-hide-add          { animation:0.5s slideUp ease; }


.gt-dashboard  .ng-hide-remove      {  animation:0.5s slideDown ease; }
*/
@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}
@keyframes slide {
  100% {
    left: 0;
  }
}
/* ANIMATIONS (FROM ANIMATE.CSS) ======================== */
/* flip in */
@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
/* light speed out */
@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}
@-webkit-keyframes display-none-transition {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
  }
  100% {
    opacity: 1;
  }
}
.gearSpin {
  -webkit-animation: rotation 2s infinite linear;
  -moz-animation: rotation 2s infinite linear;
  -o-animation: rotation 2s infinite linear;
  animation: rotation 2s infinite linear;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.required:after {
  content: " ( R )";
  color: red;
}
.gt-form {
  margin-top: 10px;
}
.gt-form .row {
  margin-bottom: 10px;
}
.modal-md {
  width: 70% !important;
}
.col-60 {
  width: 60px;
}
.col-40 {
  width: 40px;
}
.modal-body .even {
  background-color: #ffdddd;
}
.gt-modal-body-scroll {
  max-height: calc(100vh - 165px) !important;
  overflow-y: auto  !important;
}
.dashboard-loading {
  width: 100%;
  min-height: 1250px;
  text-align: center;
  z-index: 840;
  background-color: #c2cfcc;
}
.dashboard-loading .loading-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.dashboard-loading i {
  margin-top: 100px;
  color: #cd5763;
}
.modal-loading {
  width: 100%;
  min-height: 250px;
  text-align: center;
}
.modal-loading .loading-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.fade-blue {
  font-size: 12px;
}
.fade-blue .tooltip-inner {
  background-color: #00acd6;
  color: #fff;
}
.fade-blue .tooltip.top .tooltip-arrow {
  border-top-color: #00acd6;
}
.fade-blue .tooltip.right .tooltip-arrow {
  border-right-color: #00acd6;
}
.fade-blue .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #00acd6;
}
.fade-blue .tooltip.left .tooltip-arrow {
  border-left-color: #00acd6;
}
.batch-badge {
  font-size: 11px;
  color: #ffffff;
}
.badge-xs {
  min-width: 10px;
  padding: 2px 6px;
  font-size: 12px;
}
.badge-danger {
  background-color: #d9534f;
}
.badge-warning {
  background-color: #f0ad4e;
}
.badge-success {
  background-color: #5cb85c;
}
.badge-info {
  background-color: #5bc0de;
}
.badge-inverse {
  background-color: #333333;
}
.btn-default {
  background-color: #d7d7d7;
}
.btn-primary {
  background-color: #29c7f7;
}
.btn-success {
  background-color: #33cc33;
}
.btn-info {
  background-color: #1ca5cf;
}
.btn-warning {
  background-color: #ed8000;
}
.btn-danger {
  background-color: #ee3324;
}
.btn-link {
  background-color: #29c7f7;
}
.alert {
  margin-top: 20px;
}
hr {
  margin-top: 10px  !important;
  margin-bottom: 5px  !important;
}
.modal-lg {
  width: 92% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 25px !important;
}
.modal-dialog {
  -moz-border-radius: 5px 5px 5px 5px !important;
  -webkit-border-radius: 5px 5px 5px 5px !important;
  border-radius: 5px 5px 5px 5px !important;
}
.modal-content {
  -moz-border-radius: 6px 6px 6px 6px !important;
  -webkit-border-radius: 6px 6px 6px 6px !important;
  border-radius: 6px 6px 6px 6px !important;
}
.modal-header {
  border-radius: none !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  background-color: #cd5763;
  color: #ffffff;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  height: 38px;
  -webkit-box-shadow: inset 0 -2px 2px 0 #c73e4c;
  box-shadow: inset 0 -2px 2px 0 #c73e4c;
  border-bottom: none !important;
}
.modal-header i {
  color: #ffffff !important;
  transition: transform 0.2s ease;
}
.modal-header i:hover {
  transform: scale(1.4);
}
.modal-danger .modal-header {
  background-color: #ee3324;
  -webkit-box-shadow: inset 0 -2px 2px 0 #8a1e15;
  box-shadow: inset 0 -2px 2px 0 #8a1e15;
}
.modal-info .modal-header {
  background-color: #1ca5cf;
  -webkit-box-shadow: inset 0 -2px 2px 0 #1f6e86;
  box-shadow: inset 0 -2px 2px 0 #1f6e86;
}
.modal-warning .modal-header {
  background-color: #f4b366;
  -webkit-box-shadow: inset 0 -2px 2px 0 #ed8000;
  box-shadow: inset 0 -2px 2px 0 #ed8000;
}
.modal-success .modal-header {
  background-color: #33cc33;
  -webkit-box-shadow: inset 0 -2px 2px 0 #279d27;
  box-shadow: inset 0 -2px 2px 0 #279d27;
}
.modal-title {
  font-size: 18px !important;
  line-height: 38px !important;
  margin: 0 !important;
}
.modal-title i {
  color: #000000;
  cursor: pointer;
  margin-top: 7px;
}
.modal-title i:hover {
  color: #000000;
}
.col-md-btn {
  width: 25px !important;
  font-size: 22px !important;
  padding-top: 10px !important;
}
/* UI SELECT OVERRIDE */
.code-combo-box-key {
  margin-bottom: 10px;
  border: 1px solid #c0c0c0;
  padding: 5px 0px 5px 10px;
  background: #eeeeee;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  overflow: hidden;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.code-combo-box-key i {
  margin-right: 5px;
}
.code-combo-box {
  margin-bottom: 10px;
  border: 1px solid #c0c0c0;
  padding: 5px 0px 5px 10px;
  background: #ffffff;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.code-combo-box .row {
  margin-bottom: 10px;
}
.ui-select-container .btn-default[disabled] {
  background-color: #eeeeee !important;
  border-color: #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  opacity: 1;
}
.ui-select-container .btn-default {
  background: #ffffff!important;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.ui-select-container .form-control {
  line-height: 30px;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-white {
  background-color: #ffffff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.ui-select {
  background-color: #ffffff !important;
}
/* modal dialog with flip animation "NO NEED.."*/
.modal-window-lock-class .modal-lock-hdr-class:before {
  content: "\f023";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  color: #fff;
  font-size: 32px;
  left: 50%;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px 2px 3px #ad0000;
  top: -18px;
  height: 28px;
}
.gt-user-details {
  margin-top: 15px;
}
.gt-user-details .resImgHolder {
  overflow: hidden;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin: 0 0px 0 0px;
}
.gt-user-details h3 {
  margin: 0 5px 8px 0;
}
.gt-user-details p {
  margin: 0 0px 7px 0;
  font-size: 13px;
}
.gt-user-details .profile {
  margin: 0 0px 0px 0;
  padding: 0 0px 0px 0;
}
.gt-user-details .label {
  font-size: 14px;
}
.gt-user-details select {
  width: 130px;
}
.drop-box {
  background-color: #fcf8e3;
  height: 50px;
  border-bottom-color: #fbeed5;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0 0 0 0;
  border-image-repeat: stretch stretch;
  border-image-slice: 100% 100% 100% 100%;
  border-image-source: none;
  border-image-width: 1 1 1 1;
  border-left-color: #fbeed5;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: #fbeed5;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: #fbeed5;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-style: solid;
  border-top-width: 1px;
  color: #c09853;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 35px;
  padding-top: 8px;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  cursor: pointer;
}
.gtw-progress-bar-border {
  font-size: 1px;
  font-weight: bold;
  color: #3B73B9;
  outline: 2px dashed white;
  box-shadow: 0 0 0 2px #3B73B9;
  animation: 1s animateBorderOne ease infinite;
}
.app-slide-for-sys-msg {
  margin-top: 50px;
}
.nav-open .main-panel {
  margin-left: 94px;
}
.nav-open .nav-panel {
  float: left;
}
.nav-closed .main-panel {
  margin-left: 6px;
}
.nav-closed .nav-panel {
  left: -88px;
  margin: 0 ;
}
.nav-closed .nav-panel:hover {
  left: 0;
  margin: 0 ;
  z-index: 1102 ;
}
.nav-panel-admin {
  display: block;
  min-width: 700px;
  font-family: 'PwC Helvetica Neue', sans-serif !important;
  min-height: 100%;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  -ms-transition: all .5s ease;
  transition: all .5s ease;
}
.nav-panel-admin .nav-header {
  background-color: #383436;
  color: #0d0d0d;
  height: 30px;
  margin-bottom: 2px;
}
.nav-panel-admin .nav-header .tax-year {
  margin: 5px 0px 0px 10px;
}
.nav-panel-admin .nav-header .menu-expand i {
  color: #5d5f5f !important;
  font-size: 14px  !important;
  margin: 8px 5px 0px 0px;
  cursor: pointer ;
}
.nav-panel-admin .nav-header .menu-expand i:hover {
  color: #f5857c !important;
}
.nav-panel-admin .nav-header .toggle-nav {
  height: 30px;
  background-color: #4c4f4f;
  width: 30px;
  text-align: center;
  line-height: 30px;
  float: right;
  cursor: pointer;
}
.nav-panel-admin .nav-header .toggle-nav:hover {
  background-color: #f5857c;
}
.nav-panel-admin .nav-header i {
  font-size: 16px;
}
.nav-panel-admin .nav-header .search-input {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background-color: #5d5f5f;
  height: 22px;
  width: 170px;
  float: left;
  margin: 4px 0px 0px 8px;
  transition: background-color 0.5s ease;
  color: #383436;
}
.nav-panel-admin .nav-header .search-input input {
  height: 22px;
  background-color: transparent;
  border: none;
  float: left;
  outline: none;
  margin-left: 5px;
  width: 130px ;
}
.nav-panel-admin .nav-header .search-input input::-webkit-input-placeholder {
  color: #4c4f4f;
}
.nav-panel-admin .nav-header .search-input input::-moz-placeholder {
  color: #4c4f4f;
}
.nav-panel-admin .nav-header .search-input input::-moz-placeholder {
  color: #4c4f4f;
}
.nav-panel-admin .nav-header .search-input input::-ms-input-placeholder {
  color: #4c4f4f;
}
.nav-panel-admin .nav-header .search-input .close-x {
  font-size: 12px;
  float: right;
  line-height: 22px;
  margin-right: 5px;
  cursor: pointer;
}
.nav-panel-admin .nav-header .search-input .search {
  font-size: 14px;
  float: left;
  line-height: 22px;
  margin-left: 5px;
  cursor: pointer;
}
.nav-panel-admin .nav-header .search-input:hover {
  background-color: #d7d7d7;
}
.nav-panel-admin .nav-header .search-input:hover input::-webkit-input-placeholder {
  color: #a0a5a5;
}
.nav-panel-admin .nav-header .search-input:hover input::-moz-placeholder {
  color: #a0a5a5;
}
.nav-panel-admin .nav-header .search-input:hover input::-moz-placeholder {
  color: #a0a5a5;
}
.nav-panel-admin .nav-header .search-input:hover input::-ms-input-placeholder {
  color: #a0a5a5;
}
.nav-panel-admin div[gt-user-assign-menu] .gt-header {
  padding: 5px 10px;
  height: 30px;
  overflow: hidden;
  background-color: #d7d7d7;
}
.nav-panel-admin div[gt-user-assign-menu] .gt-header i {
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
}
.nav-panel-admin div[gt-user-assign-menu] .btn {
  min-width: 100px;
  margin-right: 5px;
}
.nav-panel-admin div[gt-user-assign-menu] .btn .btn-text {
  padding-left: 2px;
  float: left;
  overflow: hidden;
  width: 65px;
  text-overflow: ellipsis;
}
.nav-panel-admin div[gt-user-assign-menu] .btn .caret-icon {
  border-left: 1px solid #8a8a8a;
  height: 20px;
  vertical-align: top;
  margin: -1px 3px;
  padding: 4px 0px 0 6px;
  position: relative;
  float: right;
}
.nav-panel-admin div[gt-user-assign-menu] .btn-default {
  background-color: #eaeaea !important;
  color: #333 !important;
  border: 1px solid #ccc !important;
}
.nav-panel-admin div[gt-user-assign-menu] ul > li[class*="level-"] > div.screen:first-child {
  overflow: visible !important;
}
.nav-panel-admin div[gt-user-assign-menu] ul > li[class*="level-"]:before,
.nav-panel-admin div[gt-user-assign-menu] ul > li[class*="level-"]:after {
  clear: both;
  content: '';
  display: table;
}
.card {
  width: 29%;
  float: left;
  display: flex;
  margin: 5px 25px;
  word-wrap: break-word;
  flex-direction: column;
  background-color: #fff;
  border-top-left-radius: 0;
  background-clip: border-box;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.card .activity-num {
  width: 40px;
  color: #fff;
  margin-top: -1px;
  padding: 7px 0px;
  font-weight: 600;
  margin-left: -41px;
  text-align: center;
  position: absolute;
  border-top-left-radius: 3px;
  background-color: #ac6365;
  border-bottom-left-radius: 3px;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card .card-header {
  margin-bottom: 0;
  color: #363234;
  font-style: italic;
  padding: .45rem .85rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card .card-header .ellip {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}
.card .card-header .user {
  margin-left: 10px;
  color: #484848;
  float: right;
  margin-top: 3px;
}
.card .card-header .assign-dropdown {
  padding: 0px 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.card .card-header .pls-icon {
  top: 2px;
  font-size: 18px;
  cursor: pointer;
  margin-left: 7px;
  position: relative;
}
.card .card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
}
.card .card-body .channel {
  color: #fff;
  font-weight: bold;
  padding: 2px 20px;
  border-radius: 3px;
  text-align: center;
  display: inline-block;
  background-color: #cd5763;
}
.card .card-body .user-assign-btn-group {
  float: right;
}
.card .card-body .user-assign-btn-group .user-btn {
  color: #333;
  padding: 0 5px 0px 0px;
  border: 1px solid #b5b5b5;
}
.card .card-body .user-assign-btn-group .user-btn:focus {
  outline: none;
}
.card .card-body .user-assign-btn-group .user-icn {
  width: 23px;
  padding: 4px 0px;
  margin-right: 6px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: 1px solid #b5b5b5;
  background-color: rgba(121, 121, 121, 0.4);
}
.user-popOver {
  color: #fff;
  background-color: #333;
}
.user-popOver .arrow::after {
  border-right-color: #333 !important;
}
.user-popOver ul {
  margin: 0px;
  list-style: none;
  padding: 5px 10px;
  font-style: italic;
}
.user-popOver ul:not(:last-child) {
  border-bottom: 1px solid #fff;
}
.tree-structure {
  list-style: none;
  margin-top: 15px;
  position: relative;
}
.tree-structure:before {
  top: 0px;
  left: 20px;
  content: '';
  bottom: 15px;
  margin-top: -15px;
  position: absolute;
  border-left: 1px solid #146cc3;
}
.tree-structure > li {
  position: relative;
}
.tree-structure > li > div:before {
  top: 18px;
  left: -20px;
  width: 20px;
  content: '';
  position: absolute;
  border-top: 1px solid #146cc3;
}
.tree-structure > li.has-children > div > span.task {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  background-color: #717171;
  color: #fff;
  border: none;
  display: inline-block;
}
.tree-structure > li:not(.has-children) > div {
  padding: 5px 2px;
  margin-bottom: 6px;
  border-radius: 3px;
  border: 1px dashed #146cc3;
  display: flex;
}
.tree-structure > li:not(.has-children) > div .task {
  flex: 4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tree-structure > li:not(.has-children) > div .dropdown {
  flex: 1;
}
.tree-structure > li:not(.has-children):before {
  height: 32px;
  position: absolute;
  width: 0px;
  border: none;
  border-left: 1px solid #2089e3;
  margin-top: -17px;
  margin-left: -25px;
}
.tree-structure > li:not(.has-children):after {
  width: 22px;
  position: absolute;
  height: 0px;
  border: none;
  border-top: 1px solid #2089e3;
  margin-left: -25px;
  margin-top: -6px;
}
.ui-tabs-scrollable {
  position: relative;
  background-color: #b9b6b6;
}
.ui-tabs-scrollable > .spacer:not(.hidden-buttons) {
  margin-left: 25px;
  margin-right: 25px;
}
.ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .nav-tabs > li:first-child.active a,
.ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .nav-tabs > li:first-child a:hover {
  border-top-left-radius: 0;
  border-left: 1px solid transparent;
}
.ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .nav-tabs > li:last-child.active a,
.ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .nav-tabs > li:last-child a,
.ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .nav-tabs > li:last-child a:hover {
  border-top-right-radius: 0;
  border-right: 1px solid transparent;
  margin-right: -1px;
}
.ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .tab-content {
  margin-left: -25px;
  margin-right: -25px;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs {
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-left: 0px;
  margin: 0;
  height: 42px;
  border-bottom: 0;
  line-height: 32px;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs .cont-wrapper {
  line-height: 28px;
  margin-top: 3px;
  padding: 0 12px 0 12px;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li {
  float: none;
  display: table-cell;
  border-right: none;
  height: inherit;
  font-size: 12px;
  cursor: pointer;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li .fa-circle {
  font-size: 10px;
  padding-left: 12px;
  position: relative;
  bottom: 1px;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li .fa-link {
  padding-right: 12px;
  font-size: 12px;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li.active,
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li:hover {
  background-color: #f9f9f9;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li > a {
  height: 42px;
}
.ui-tabs-scrollable > .spacer > div:first-child > .tab-content {
  margin-top: -1px;
  border-top: 1px solid #ddd;
}
.ui-tabs-scrollable > .nav-button {
  position: absolute;
  width: 25px;
  height: 100%;
  top: 0;
  cursor: pointer;
  border-left: 1px solid #938686;
  border-right: 1px solid #938686;
  border-radius: 0;
  padding: 0;
}
.ui-tabs-scrollable > .nav-button:hover {
  background-color: #eee;
}
.ui-tabs-scrollable > .nav-button.left-nav-button {
  left: 0;
}
.ui-tabs-scrollable > .nav-button.left-nav-button:before {
  font-family: 'Glyphicons Halflings';
  content: '\e079';
}
.ui-tabs-scrollable > .nav-button.right-nav-button {
  right: 0;
  border-top-right-radius: 4px;
}
.ui-tabs-scrollable > .nav-button.right-nav-button:before {
  font-family: 'Glyphicons Halflings';
  content: '\e080';
}
.ui-tabs-scrollable > .dropdown {
  position: absolute;
  top: 0;
  right: 0px;
}
.ui-tabs-scrollable > .dropdown > .dropdown-toggle {
  width: 25px;
  height: 42px;
  border-left: 1px solid #938686;
  border-right: 1px solid #938686;
  padding: 0;
  background-color: #ddd;
  border-radius: 0;
}
.ui-tabs-scrollable > .dropdown > .dropdown-toggle:before {
  font-family: 'Glyphicons Halflings';
  content: '\e114';
}
.ui-tabs-scrollable.show-drop-down > .spacer:not(.hidden-buttons) {
  margin-right: calc(50px);
}
.ui-tabs-scrollable.show-drop-down > .spacer:not(.hidden-buttons) > div:first-child > .tab-content {
  margin-right: calc(-50px);
}
.ui-tabs-scrollable.show-drop-down > .nav-button.right-nav-button {
  right: 25px;
  border-radius: 0;
}
.dropdown-menu > li > a .dropDownTabActiveMark:before {
  font-family: 'Glyphicons Halflings';
  content: '\e013';
  padding-right: 10px;
  font-size: 12px;
}
/*make the tabs content be height 100%*/
.ui-tabs-scrollable > .spacer {
  height: 100%;
}
.ui-tabs-scrollable > .spacer > div:first-child {
  height: 100%;
}
.ui-tabs-scrollable > .spacer > div:first-child > .tab-content {
  height: 100%;
}
.ui-tabs-scrollable > .spacer > div:first-child > .tab-content > .active {
  height: 100%;
  overflow: auto;
}
.scrollable-tabs li > a {
  padding: 5px 10px;
}
.scrollable-tabs li + li {
  border-top: 1px solid #ddd;
}
/* width */
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs::-webkit-scrollbar {
  height: 3px;
}
/* Track */
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs::-webkit-scrollbar-track {
  border-radius: 0px;
  box-shadow: inset 0 0 5px #8c8c8c;
}
/* Handle */
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #7b7b7b;
}
/** Activity Tracker directive styles in global directive */
.activity {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 3px;
}
.activity-wrapper li {
  list-style: none;
  display: inline-block;
  vertical-align: top;
}
.activity-wrapper .text-nowrap {
  width: calc(100% - 120px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.activity-wrapper .list {
  height: 58px;
}
.activity-wrapper .list:before {
  content: "";
  height: 35px;
  background-color: #ceced0;
  width: 2px;
  padding: 1px;
  position: absolute;
  margin-left: 93px;
  margin-top: 24px;
}
.activity-wrapper .seperator {
  background-color: #fff;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  margin-top: 5px;
  border-style: solid;
  margin-left: 7px;
  margin-right: 7px;
}
.gt-lazy-load-drp-btn {
  width: 100%;
  display: block;
  cursor: pointer;
  min-height: 30px;
  border-radius: 0;
  line-height: 28px;
  padding-left: 5px;
  border: 1px solid #aaa;
}
.gt-lazy-dropdown-list {
  left: auto;
  margin-top: -1px;
  border: 1px solid #aaa;
  width: calc(100% - 30px);
  border-radius: 0 0 3px 3px;
  border-top: none !important;
}
.gt-lazy-dropdown-list .list-ul {
  margin: 4px 0px;
  padding-left: 0;
  overflow-y: auto;
  list-style: none;
  max-height: 200px;
}
.gt-lazy-load-list-trunc {
  cursor: pointer;
  margin: 1px 0px;
  padding: 3px 8px;
  text-align: left;
  font-size: 13px !important;
}
.gt-lazy-load-list-trunc:hover {
  color: #fff;
  background-color: #4f99c6;
}
.sticky-search-bar {
  font-size: 13px;
  padding: 3px 8px;
}
.sticky-search-bar .input-style {
  width: 100%;
  height: 30px;
  border-radius: 0;
  padding: 4px 20px 4px 5px;
}
.sticky-search-bar .search-icn {
  top: 15px;
  right: 15px;
  color: #888;
  font-size: 14px;
  position: absolute;
}
.df-select .df-gt-lazy-load-drp-btn {
  height: 25px;
  cursor: pointer;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 3px;
}
.df-select .df-gt-lazy-dropdown-list {
  top: auto;
  left: auto;
}
.df-select .df-gt-lazy-dropdown-list .list-ul {
  padding-left: 0;
  overflow-y: auto;
  list-style: none;
  max-height: 200px;
  margin: 2px 1px 0px 1px;
}
.df-select .df-gt-lazy-load-list-trunc {
  cursor: pointer;
  text-align: left;
  padding: 2px 20px;
}
.df-select .df-gt-lazy-load-list-trunc:hover {
  background-color: #f5f5f5;
}
.df-select .sticky-search-bar {
  padding: 5px 20px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}
/* Scrollbar styles for message list left nav */
/* width */
.df-select .df-gt-lazy-dropdown-list .list-ul::-webkit-scrollbar {
  width: 8px;
}
/* Track */
.df-select .df-gt-lazy-dropdown-list .list-ul::-webkit-scrollbar-track {
  border-radius: 3px;
  background: #f7f7f7;
  box-shadow: inset 0 0 6px #797777;
}
/* Handle */
.df-select .df-gt-lazy-dropdown-list .list-ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9a9a9a;
  border: 1px solid #7d7a7a;
}
.wizard-tabs ul {
  position: relative;
  background-color: #5d5f5e;
}
.wizard-tabs ul > li.btn-sm {
  padding: 0;
}
.wizard-tabs ul li + li {
  padding: 0;
  margin-left: 0;
  border-radius: 0;
  border-left: 1px solid #000;
}
.wizard-tabs ul li:nth-last-child(2) {
  border-right: 1px solid #000;
  border-radius: 0;
}
.wizard-tabs ul > li > a {
  color: #fff;
}
.wizard-tabs ul > li > a:hover {
  background-color: transparent;
}
.wizard-tabs ul > li.active > a,
.wizard-tabs ul > li.active > a:focus,
.wizard-tabs ul > li.active > a:hover {
  border-radius: 0;
  background-color: transparent;
}
.wizard-tabs .wizard-tab-hilighter {
  left: 1px;
  font-size: 12px;
  color: #FFF;
  cursor: pointer;
  overflow: hidden;
  font-weight: 500;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  background-color: #c6545e;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  box-shadow: 0 10px 25px -9px #c6545e, 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);
}
.stepper .stepper-1 {
  height: 40px;
  width: 40px;
  background-color: #eaeaea;
  border-radius: 200px;
  display: table;
  border: 1px solid #cdcdcd;
  padding: 10px;
  position: relative;
  z-index: 100;
  font-size: 15px;
  line-height: 20px;
  outline: none;
  transition-delay: 0.4s;
}
.stepper .enabled {
  background-color: #5db75d !important;
  border: none !important;
  color: #fff !important;
}
.stepper .current-step {
  background-color: #56bdea !important;
}
.stepper .progress-container {
  position: relative;
  margin-top: 0px;
}
.stepper .progress {
  position: absolute;
  top: 18px;
  left: 50%;
  width: 70%;
  transform: translateX(50%);
  height: 5px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  left: 0% !important;
  width: 100% !important;
}
.stepper .progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5867dd;
  background-color: #5db75d !important;
  width: 0%;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.stepper .d-inline-block {
  display: inline-block !important;
}
.stepper .filter-description {
  margin-top: 0px;
}
.stepper .titleFont {
  font-size: 12px;
  color: gray;
  display: inline-flex;
  word-spacing: 0vw;
  text-transform: uppercase;
  margin-top: 5px;
}
.stepper .progressBarFullWidth {
  width: 100% !important;
}
.custom-checkbox {
  border: 1px solid;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border-color: #5c7e87;
  position: relative;
  float: left;
  background-color: white;
}
.custom-checkbox.selected {
  background-color: #54dd59;
}
.custom-checkbox .indicator.selected {
  background-color: #54dd59;
  width: 100%;
  height: 100%;
  /* margin: 4px;  */
}
.custom-checkbox .check {
  position: absolute;
  color: white;
  font-size: 13px;
}
.custom-checkbox .indicator.intermitent {
  background-color: #f6a539;
  width: 7px;
  height: 7px;
  margin: 3px;
}
.custom-checkbox-circle {
  border: 1px solid;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-color: #5c7e87;
  position: relative;
  float: left;
  background-color: white;
  margin: 5px;
}
.custom-checkbox-circle.selected {
  background-color: #54dd59;
}
.custom-checkbox-circle .indicator.selected {
  background-color: #54dd59;
  width: 100%;
  height: 100%;
  /* margin: 4px;  */
}
.custom-checkbox-circle .check {
  position: absolute;
  color: white;
  font-size: 13px;
  top: 2.5px;
  left: 3px;
}
.custom-checkbox-circle .indicator.intermitent {
  background-color: #f6a539;
  width: 7px;
  height: 7px;
  margin: 3px;
}
.workflow-view .custom-tree-view .label {
  float: left;
  margin-left: 0px;
  line-height: 10px;
  font-size: 14px;
  color: black;
  font-weight: 200;
}
.workflow-view .custom-tree-view .label.level-1 {
  text-transform: uppercase;
}
.workflow-view .custom-tree-view .tree-level.level-1 {
  background-color: #97dff5;
}
.workflow-view .custom-tree-view .tree-level {
  clear: both;
  background-color: #eeeced;
  height: 26px;
  padding-top: 3px;
  border: 0.5px solid white;
  width: 100%;
}
.workflow-view .custom-tree-view .node.not-preview-mode {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.workflow-view .custom-tree-view .node.preview-mode {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.workflow-view .custom-tree-view .node {
  overflow: hidden;
}
.workflow-view .custom-tree-view .version-tag {
  border: 1px solid black;
  border-radius: 50%;
  font-size: 9px;
  width: 18px;
  text-align: center;
  line-height: 16px;
  background-color: #f7f7c3;
}
.workflow-view .custom-tree-view .node-editor {
  height: 18px;
  margin-left: 5px;
  margin-top: -1px;
  position: absolute;
  border-radius: 4px;
  padding: 5px;
  border: 0.5px solid #ccc;
}
.workflow-view .custom-tree-view .btn-custom {
  height: 20px;
  margin-top: -2px;
  padding: 5px;
  font-size: 14px;
  line-height: 0.5;
  color: white;
  border: #ccc 1px solid;
  border-radius: 5px;
  background-color: orange;
  cursor: default;
}
.workflow-view .custom-tree-view .btn-custom-left {
  height: 20px;
  margin-top: -3px;
  padding: 0px;
  font-size: 10px;
  line-height: 0.5;
  color: white;
  border: #ccc 1px solid;
  border-radius: 5px;
  background-color: silver;
}
.workflow-view .custom-tree-view .edit button {
  float: left;
  padding: 0px 15px;
  color: #000;
  font-size: 12px;
}
.workflow-view .custom-tree-view .edit {
  margin-right: -19%;
  border-radius: 5px;
  background-color: #eeeced;
  /* border: 1px solid #adadad; */
  /* display: inline-block; */
  width: 400px;
  padding: 0;
  font-family: sans-serif;
  max-height: 22px;
  padding: 1px 0 0;
  font-size: 12px;
  line-height: 17px;
  margin-top: -3px;
  float: right;
}
.workflow-view .custom-tree-view .Push button {
  background-color: #5db75d;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #8c8989;
  height: 21px;
  color: white;
}
.workflow-view .custom-tree-view .ignore button {
  background-color: grey;
  color: white;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid #8c8989;
  height: 21px;
}
.workflow-view .custom-tree-view .new {
  float: left;
  padding: 0px 12px 0px 9px;
  margin-left: 1.3em;
}
.workflow-view .custom-tree-view .editright {
  float: right;
  width: 69%;
  padding: 1px;
  margin-top: -3px;
}
.workflow-view .custom-tree-view .modal-label {
  height: 20px;
  margin-top: -2px;
  padding: 5px;
  font-size: 14px;
  line-height: 0.5;
  color: white;
  border: #ccc 1px solid;
  border-radius: 5px;
  background-color: #ed97a0;
  cursor: default;
  margin-right: 1em;
}
.workflow-view .custom-tree-view .modal-label-left {
  height: 20px;
  margin-top: -3px;
  padding: 0px;
  font-size: 10px;
  line-height: 0.5;
  color: white;
  border: #ccc 1px solid;
  border-radius: 5px;
  background-color: silver;
}
.application-view .custom-tree-view .label {
  float: left;
  margin-left: 0px;
  line-height: 10px;
  font-size: 14px;
  color: black;
  font-weight: 200;
}
.application-view .custom-tree-view .label.level-1 {
  text-transform: uppercase;
}
.application-view .custom-tree-view .tree-level.level-1 {
  background-color: #97dff5;
}
.application-view .custom-tree-view .tree-level {
  clear: both;
  background-color: #eeeced;
  height: 26px;
  padding-top: 5px;
  border: 0.5px solid white;
  width: 100%;
}
.application-view .custom-tree-view .node.not-preview-mode {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.application-view .custom-tree-view .node.preview-mode {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.application-view .custom-tree-view .node {
  overflow: hidden;
}
.application-view .custom-tree-view .version-tag {
  border: 1px solid black;
  border-radius: 50%;
  font-size: 9px;
  width: 18px;
  text-align: center;
  line-height: 16px;
  background-color: #f7f7c3;
}
.application-view .custom-tree-view .node-editor {
  height: 18px;
  margin-left: 5px;
  margin-top: -1px;
  position: absolute;
  border-radius: 4px;
  padding: 5px;
  border: 0.5px solid #ccc;
}
#add-new-client-container .slimScrollDiv {
  background: #ddd;
}
#add-new-client-container .row.search-bar {
  padding: 4px 0;
  background-color: #ddd;
}
#add-new-client-container .row.clients-tree,
#add-new-client-container .row.clients-tree .no-padding {
  padding: 0;
}
#add-new-client-container .cleint-type {
  color: #ddd;
  padding: 4px 0;
  padding-top: 2px;
  padding-bottom: 2px;
}
#add-new-client-container .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}
#add-new-client-container .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
#add-new-client-container .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
#add-new-client-container .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
#add-new-client-container input:checked + .slider:before {
  left: -3px !important;
}
#add-new-client-container input:checked + .slider {
  background-color: #5db75d;
}
#add-new-client-container input:focus + .slider {
  box-shadow: 0 0 1px #5db75d;
}
#add-new-client-container input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
#add-new-client-container .slider.round {
  border-radius: 34px;
}
#add-new-client-container .slider.round:before {
  border-radius: 50%;
}
#add-new-client-container .treeview-set.half-width .slimScrollDiv {
  width: 49.8% !important;
}
#add-new-client-container .treeview-set.full-width .slimScrollDiv {
  width: 99.4% !important;
}
#add-new-client-container .preview-label {
  float: right;
  padding-top: 0px;
  line-height: 20px;
  padding-left: 9px;
  font-weight: bolder;
  text-transform: uppercase;
}
#add-new-client-container .btn-collapse-all {
  font-size: 25px;
  float: right;
  line-height: 10px;
  padding: 6px;
  color: #0f0f0f8a;
  margin-right: 5px;
  cursor: pointer;
}
#add-new-client-container .add-workflow {
  cursor: pointer;
  margin-left: 5px;
}
#add-new-client-container .treeview-set .slimScrollDiv {
  display: inline-block;
  width: 100% !important;
}
#add-new-client-container .stack-label-workflow {
  width: 315px;
  height: 20px;
  text-align: left;
  text-transform: capitalize;
  font-size: initial;
}
#workflow-setup div.form-group {
  float: left;
  margin-bottom: 10px;
}
#workflow-setup .tax-year {
  margin-left: 20px;
}
#workflow-setup .scenario {
  margin-left: 20px;
}
#workflow-setup .filter-arrow-img {
  width: 100%;
  position: relative;
}
#workflow-setup .btn-radio {
  background-color: lightgray;
  font-weight: bold;
}
#workflow-setup .btn-primary {
  background-color: #78cfea;
  border-color: #74c3dc;
  box-shadow: none;
  color: #444;
}
#workflow-setup .btn-primary:hover {
  background-color: #78cfea;
  border-color: #74c3dc;
  box-shadow: none;
  color: #444;
}
#workflow-setup input[type="radio"] {
  visibility: hidden;
}
#workflow-setup input[type="checkbox"],
#workflow-setup input[type="radio"] {
  margin: 4px -7px 0px;
  line-height: normal;
}
#workflow-setup .dropdown {
  padding: 8px 10px;
  border-radius: 4px;
  outline-width: 1px;
}
#workflow-modal .workflow-modal-input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  margin-top: 5px;
}
#workflow-modal .workflow-modal-select {
  padding: 8px 10px;
  border-radius: 4px;
  outline-width: 1px;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 61%;
  -webkit-padding-end: 0px !important;
  -webkit-padding-start: 4px !important;
}
.position-relative {
  position: relative;
}
/* The container */
.chk-box-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.chk-box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  right: 0;
  height: 20px;
  width: 20px;
  border: 1.5px solid #2b2b2b;
}
/* On mouse-over, add a grey background color */
.chk-box-container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a Grey background */
.chk-box-container input:checked ~ .checkmark {
  background-color: #E7E7E7;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.chk-box-container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.chk-box-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #202020;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.gt-hierarchy-drop-down .gt-drop-container {
  position: absolute;
  z-index: 900;
}
.gt-hierarchy-drop-down .drop-down-btn {
  background-color: #4c4f4f;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  line-height: 27px;
  height: 25px;
  color: #cbc8c8;
  width: 140px;
  padding: 0px 8px;
  font-size: 12px;
}
.gt-hierarchy-drop-down .drop-down-btn i {
  float: right;
  line-height: 25px;
}
.gt-hierarchy-drop-down .drop-down-btn .badge {
  float: right;
  padding: 2px 5px;
  font-size: 11px;
  background-color: #f5857c;
  margin: 5px 5px 0px 0px;
}
.gt-hierarchy-drop-down .gt-drop-container .left-menu {
  width: 200px;
  float: left;
  background-color: #1f1f1f;
  font-family: 'PwC Helvetica Neue', sans-serif !important;
}
.gt-hierarchy-drop-down .gt-drop-container .left-menu .unselect {
  cursor: pointer;
  text-align: right;
  font-size: 11px;
  text-decoration: underline;
  background-color: #111111;
  padding: 5px;
  margin-bottom: 1px;
  color: #cbc8c8;
}
.gt-hierarchy-drop-down .gt-drop-container .left-menu li {
  list-style: none;
  color: #cbc8c8;
  background-color: #303030;
  padding: 1px 5px;
  cursor: pointer;
  font-size: 13px;
}
.gt-hierarchy-drop-down .gt-drop-container .left-menu li:hover,
.gt-hierarchy-drop-down .gt-drop-container .left-menu li.selected {
  background-color: #474747;
}
.gt-hierarchy-drop-down .gt-drop-container .left-menu li input {
  margin: 5px 6px 0px 5px;
}
.gt-hierarchy-drop-down .gt-drop-container .left-menu li .badge {
  float: right;
  margin: 2px 2px 0px 0px;
}
.gt-hierarchy-drop-down .gt-drop-container .right-menu {
  min-width: 590px;
  float: left;
  border: solid 1px #2b2b2b;
  background-color: #d7d7d7;
  min-height: 250px;
}
.gt-hierarchy-drop-down .gt-drop-container .right-menu > ul {
  padding: 10px;
  margin: 0px;
}
.gt-hierarchy-drop-down .gt-drop-container .right-menu > ul > li {
  font-weight: bold;
  font-size: 13px;
  color: #13161a;
}
.gt-hierarchy-drop-down .gt-drop-container .right-menu > ul > li > ul > li {
  font-weight: bold;
  font-size: 12px;
}
.gt-hierarchy-drop-down .gt-drop-container .right-menu li {
  list-style: none;
  padding: 3px 5px;
  cursor: pointer;
  font-size: 11px;
  font-weight: normal;
}
.gt-hierarchy-drop-down .gt-drop-container .right-menu li input {
  float: left;
  margin-right: 5px;
}
div.dashboard-container {
  background-color: #f2f2f2;
  margin-bottom: 32px;
  padding-bottom: 45px;
}
td.details-control {
  background: url(/assets/img/details_open.png) no-repeat center center;
  cursor: pointer;
}
.btn-tranparent:hover,
.btn-tranparent:focus {
  color: white !important;
}
tr.shown td.details-control {
  background: url(/assets/img/details_close.png) no-repeat center center;
  cursor: pointer;
}
.grid-wrapper {
  height: 100%;
  position: absolute;
  width: 100%;
}
.full-height {
  height: 100%;
}
.padding-top-20 {
  padding-top: 20px;
}
.sticky-header thead tr th {
  position: sticky !important;
  top: 0;
}
.datatable-dropdown-wrapper {
  display: none;
}
.popover-full {
  top: 50px !important;
  margin-left: -8px;
}
.popover-arrow-expand {
  top: -11px;
  bottom: 0px !important;
  border-top-color: none !important;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  transform: rotate(180deg);
}
.clearfix {
  clear: both;
}
#table {
  width: 100% !important;
}
#foriegn-table,
#domestic-table {
  width: 100% !important;
}
table.dataTable {
  margin-top: 0px !important;
}
.ui-dialog {
  border: 1px solid #ddd;
  z-index: 1000;
}
.ui-dialog .ui-dialog-titlebar {
  background: #cd5763;
  color: white;
  height: 30px;
  padding: 5px;
}
.ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  font-weight: bold;
}
.ui-dialog .ui-dialog-titlebar .ui-button.ui-dialog-titlebar-close {
  float: right;
  height: 25px;
  padding: 0px 13px 0px 13px;
  margin-top: -3px;
  border: none;
  border-radius: 5px;
  background: white;
  color: black;
}
.ui-widget-content {
  width: 100%;
}
.ui-draggable-handle {
  cursor: move;
}
.highcharts-text-outline {
  fill: none;
  stroke: none;
}
.treeMapWrapper {
  min-width: 300px;
  max-width: 100%;
  margin: 0 auto;
  height: 100px;
}
.treeMapWrapper .treemap {
  margin-top: 5px;
}
.treeMapWrapper .highcharts-background {
  fill: none;
}
.treeMapWrapper .highcharts-exporting-group {
  display: none;
}
.treeMapWrapper .highcharts-container {
  overflow: hidden !important;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  width: 100% !important;
  height: 85px !important;
}
.treeMapWrapper .highcharts-container::-webkit-scrollbar {
  height: 6px !important;
}
.treeMapWrapper .highcharts-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
.treeMapWrapper .highcharts-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.treeMapWrapper rect.highcharts-point {
  cursor: pointer;
}
.percentage-bar-parent {
  width: 100%;
  height: 60px;
  max-height: 50px;
  display: inline-block;
}
.percentage-bar {
  text-align: center;
  float: left;
  height: 100%;
  padding: 2px;
  white-space: nowrap;
}
.percentage-bar p {
  color: white;
  font-size: 12px;
  position: relative;
}
.taxableIncome {
  background-color: maroon;
}
.booxToTax {
  background-color: cadetblue;
}
.percentage-bar p {
  margin: 0px !important;
}
.percentage-bar p.percentage {
  font-size: 20px;
  font-weight: bold;
}
.greyFooter th {
  background-color: grey !important;
}
.pinkFooter th {
  background-color: #cd5763 !important;
}
tfoot.normalFooter th {
  background-color: #f9f9f9 !important;
  color: #333333;
  font-weight: normal;
}
.treeMapWrapper {
  position: relative;
}
.treeMapWrapper .fa-expand {
  position: absolute;
  top: 0px;
  right: 10px;
}
.kpi-box {
  padding: 2px 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  background-color: #fff;
}
.kpi-box label {
  display: block !important;
  text-align: center;
  margin-bottom: 0px !important;
  font-size: 14px;
}
.kpi-box p {
  margin: 0px !important;
  text-align: center;
}
.kpi-box p.amount {
  font-size: 26px;
  font-weight: bold;
  color: darkcyan;
  text-overflow: ellipsis;
  overflow: hidden;
}
.kpi-box-container {
  height: 70px;
  margin: 0px -8px !important;
}
.kpi-box-container .component-error-message {
  font-size: 10px;
}
.toggleButtonContainer {
  padding: 0px 10px;
  position: absolute;
  z-index: 1;
  background: transparent;
}
.toggleButtonContainer .fa {
  transition: all .5s;
  transform: none !important;
  z-index: 0;
}
.toggleButtonContainer .fa.fa-arrow-left {
  margin-right: 5px;
}
.toggleButtonContainer .fa.fa-arrow-right {
  margin-left: 5px;
}
.toggleButtonContainer:focus {
  outline: 0 !important;
}
.toggleButtonContainer:active {
  box-shadow: none;
}
.icon-compressed .peices:nth-of-type(3) {
  width: 3px;
}
.icon-compressed .peices:nth-of-type(2) {
  width: 5px;
}
.icon {
  cursor: pointer;
}
.icon .peices {
  width: 8px;
  background: #4eb523;
  height: 20px;
  float: left;
  border: 0.5px solid white;
  transition-duration: 0.2s;
}
.icon i {
  float: left;
  transition-duration: 0.2s;
  line-height: 17px;
  margin: 2px;
}
.icon .streching {
  margin-left: 1px;
  margin-right: 1px;
}
.icon .rotate {
  transform: rotate(180deg);
}
.popover-title {
  font-weight: bold;
  font-size: 16px !important;
}
.popover .table-section select.form-control {
  font-size: 10px !important;
}
#wwniTable.table {
  width: 100%;
}
#wwniTable.table .group {
  background: lightslategrey;
  color: white;
}
.highcharts-container {
  width: 100% !important;
}
#transactionStepTable {
  width: 100% !important;
}
#transactionDataTable {
  width: 100% !important;
}
#transactionDataTable tbody tr td:nth-child(1) {
  position: relative;
}
#transactionDataTable .fa-paperclip {
  cursor: pointer;
  transition: none;
  position: absolute;
  top: calc(43%);
  transform: none;
}
.project-details .btn-group .project-header-btn {
  height: 30px;
  background-color: #1f1f1f;
  color: #d7d7d7;
  border-radius: 3px;
  border: 1px solid #4c4f4f;
  padding: 5px 15px;
}
.project-details .btn-group .project-header-btn--active {
  color: #ffffff;
  background-color: #cd5763;
}
.gt-dashboard-ex .gt-dashboard-ex-header > h4 {
  width: calc(100% - 185px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.gt-dashboard-ex .popover {
  border-top: 5px solid #d95369;
  border-radius: 0;
  font-size: 12px;
}
.gt-dashboard-ex .popover .chart-type a {
  padding: 8px;
  margin: 4px;
}
.gt-dashboard-ex .popover .sub-header {
  padding-left: 20px;
}
.gt-dashboard-ex .popover .gt-popover {
  width: 160px;
  margin-top: 10px;
  padding: 0px 10px;
}
.gt-dashboard-ex .popover .fa {
  transform: none !important;
  font-size: 22px;
  font-weight: 100;
}
.gt-dashboard-ex .popover .fa:focus {
  box-shadow: none !important;
}
.gt-dashboard-ex .popover .fa.fa-bar-chart {
  color: #6495ed;
}
.gt-dashboard-ex .popover .fa.fa-line-chart {
  color: #ff7f50;
}
.gt-dashboard-ex .popover .fa.fa-area-chart {
  color: #663399;
}
.gt-dashboard-ex .popover .fa.fa-pie-chart.pie {
  color: #5f9ea0;
}
.gt-dashboard-ex .popover .fa.fa-pie-chart.donut {
  color: #b22222;
}
.popover-content {
  padding: 0px !important;
}
.select-accordion {
  text-align: center;
  padding: 10px 30px;
  color: white;
  cursor: pointer;
  box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.16);
}
.select-accordion.select-accordion-blue {
  background: cadetblue;
}
.select-accordion.select-accordion-maroon {
  background: firebrick;
}
.select-accordion .fa {
  margin-bottom: 10px;
  font-size: 22px !important;
  transform: none;
}
.select-accordion .fa-angle-down {
  margin-bottom: 0px !important;
  margin-top: 10px;
  transform: none;
}
.select-accordion p {
  margin: 0px !important;
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.table .group {
  background: lightslategrey !important;
  color: white;
}
.schdM-menu-active {
  background: darkslategrey;
  color: white;
}
.viewport-center {
  top: 50vh !important;
  transform: translateY(-70px) !important;
}
.z-index-gg-loader {
  z-index: 778;
}
.gg-loader-blur-bg {
  background: #f2f2f2;
  width: calc(100% - 95px);
  height: calc(100vh - 2px);
  margin-top: 2px;
}
.loading-large {
  width: 3rem !important;
  height: 3rem !important;
}
.schdM-menu .schdM-menu-search {
  margin-bottom: 0px;
}
.schdM-menu .schdM-menu-search input[type="search"] {
  width: 100%;
  position: sticky;
  top: 0px;
  left: 0px;
  padding-left: 5px;
  border: none;
  height: 34px;
}
#table-1 th,
#table-1 td {
  white-space: nowrap;
}
div.dts div.dataTables_scrollBody {
  background: none !important;
}
div.dts tbody th,
div.dts tbody td {
  white-space: normal;
}
.dts_label {
  display: none !important;
}
div.dataTables_processing {
  z-index: 1 !important;
}
.v-middle {
  vertical-align: middle !important;
  padding-left: 5px;
}
.min-width-250 {
  min-width: 250px;
}
.table.message-table {
  background: #bbbbbb;
  color: black;
  border: 1px solid black;
}
.table.message-table tbody {
  border: 1px solid black;
}
.table.message-table tbody tr {
  border: 1px solid black;
}
.table.message-table tbody tr td {
  border: 1px solid black;
}
.table.message-table tbody tr td .delete-icon {
  font-size: 20px;
}
.table.message-table tbody tr td .edit-icon {
  font-size: 20px;
  margin-top: 10px;
}
.table.message-table tbody tr td .file-icon {
  color: black;
  font-size: 20px;
  margin-right: 10px;
}
.table.message-table tbody tr td .short-name {
  display: inline-block;
  position: relative;
  /*  top: -10px; */
  padding-right: 5px;
}
.table.message-table tbody tr td .name-time-wrapper {
  display: inline-block;
}
.table.message-table tbody tr td .name-time-wrapper .message-name {
  font-size: 20px;
}
.table.message-table tbody tr td .message-attachments:not(:first-child) {
  margin-top: 5px;
}
.table.message-table tbody tr td .file-name {
  position: relative;
  top: -2px;
}
div.comment-table {
  display: none;
}
.table .fa {
  transform: none ;
  transition: none ;
}
.table .info-icon {
  float: right;
  color: midnightblue;
  font-size: 16px;
  cursor: pointer;
}
.table .info-icon.info-icon-active {
  color: firebrick !important;
}
.message-tr .td {
  padding: 0px;
}
.datatable-revenue,
.dt-cell-data.amount,
.dt-cell-data.percent,
.dt-cell-data.number {
  float: right;
  margin-right: 5px;
}
tbody tr.totals-row {
  background-color: #878b96 !important;
  color: white;
  font-weight: bold;
}
.datatable-dropdown-wrapper {
  position: relative;
  top: -2px;
  /*  margin-left: 5px; */
  float: left !important;
}
.datatable-dropdown-wrapper .add-comment-btn {
  padding: 0px 5px;
  background: white;
  height: 20px;
  border: 1px solid #646464;
}
.datatable-dropdown-wrapper .add-comment-btn .caret {
  position: relative;
  top: 0px;
  float: left !important;
}
tr:hover .datatable-dropdown-wrapper {
  display: inline-block;
}
.dataTables_scrollBody::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
.dataTables_scrollBody::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
.dataTables_scrollBody::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.custom-tree-chart {
  height: 100px;
}
.custom-tree-chart .nodes {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  white-space: nowrap;
}
.custom-tree-chart .nodes::-webkit-scrollbar {
  height: 6px !important;
}
.custom-tree-chart .nodes::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
.custom-tree-chart .nodes::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.custom-tree-chart .tree-node {
  display: inline-block;
  height: 80px;
  border-right: 1px solid white;
}
.custom-tree-chart .tree-node:hover {
  opacity: 0.8;
}
.custom-tree-chart .tree-node .node-details {
  color: white;
  font-size: 12px;
  font-weight: bold;
  margin: 3px 5px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 35px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-line;
}
.highcharts-drilldown-axis-label {
  color: black !important;
  text-decoration: none !important;
  font-size: 10px !important;
  fill: black !important;
  pointer-events: none;
}
.highcharts-drilldown-data-label span {
  text-decoration: none !important;
  color: black ;
}
.highcharts-tooltip span {
  padding: 0px 2px;
}
.m-top-0 {
  margin-top: 0px;
}
.comparision-chart {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: hidden;
}
.comparision-chart::-webkit-scrollbar {
  height: 6px !important;
}
.comparision-chart::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
.comparision-chart::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.comparision-chart p {
  margin: 0px !important;
}
.comparision-chart-nodes {
  display: inline-block;
  text-align: center;
  border-right: 1px dotted #adaaaa;
  z-index: 30;
  transition: .2s;
  cursor: pointer;
}
.comparision-chart-nodes:hover {
  transform: scale(1.1);
  z-index: 20;
  transition: .2s;
  box-shadow: 0px 0px 5px 2px #e0dee0;
  background: white;
}
.comparision-chart-nodes:active {
  box-shadow: none;
  transform: scale(0.8);
  transform: .2s;
}
.comparision-chart-nodes .node-name {
  font-weight: bold;
  padding: 10px;
  text-decoration: underline;
}
.comparision-chart-revenues {
  border-top: 1px dotted  #adaaaa;
  margin-top: 10px;
  height: 100px;
}
.comparision-chart-nodes-data {
  display: inline-block;
  width: 100px;
  height: 100%;
}
.comparision-chart-nodes-data .node-data-key {
  font-weight: bold;
}
.comparision-chart-nodes-data .node-data-value {
  font-weight: bold;
}
.comparision-chart-nodes-data:not(:last-child) {
  border-right: 1px dotted  #adaaaa;
}
.comparision-chart .diamond {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  position: relative;
  top: -20px;
  margin: 0 auto;
}
.comparision-chart .diamond:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 10px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
}
.comparision-chart .diamond-blue {
  border-bottom-color: blue;
}
.comparision-chart .diamond-blue::after {
  border-top-color: blue;
}
.comparision-chart .diamond-green {
  border-bottom-color: green;
}
.comparision-chart .diamond-green::after {
  border-top-color: green;
}
.comparision-chart .diamond-orange {
  border-bottom-color: orange;
}
.comparision-chart .diamond-orange::after {
  border-top-color: orange;
}
.comparision-chart .diamond-darkblue {
  border-bottom-color: darkblue;
}
.comparision-chart .diamond-darkblue::after {
  border-top-color: darkblue;
}
.comparision-chart .diamond-brown {
  border-bottom-color: brown;
}
.comparision-chart .diamond-brown::after {
  border-top-color: brown;
}
.comparision-chart .diamond-yellow {
  border-bottom-color: yellow;
}
.comparision-chart .diamond-yellow::after {
  border-top-color: yellow;
}
.comparision-chart .diamond-violet {
  border-bottom-color: violet;
}
.comparision-chart .diamond-violet::after {
  border-top-color: violet;
}
.comparision-chart .diamond-indigo {
  border-bottom-color: indigo;
}
.comparision-chart .diamond-indigo::after {
  border-top-color: indigo;
}
.comparision-chart .diamond-lightblue {
  border-bottom-color: lightblue;
}
.comparision-chart .diamond-lightblue::after {
  border-top-color: lightblue;
}
.negative-data {
  color: red !important;
}
.totals-row .negative-data {
  color: #ae1313 !important;
}
.fade-opacity {
  opacity: 0.3;
  background: #dad5d5;
}
@keyframes pushonclick {
  0% {
    transform: scale(0.9);
    box-shadow: 0px 0px 5px 2px #e0dee0;
  }
  100% {
    transform: scale(1);
    box-shadow: none;
  }
}
.context-toolbar {
  width: 100%;
  height: 30px;
  display: inline-block;
  background: #e5e5e5;
  padding: 5px 10px;
}
.context-toolbar .group-data {
  float: right;
  height: 100%;
}
.context-toolbar .group-data .fa-object-group {
  color: #993d46;
}
.context-toolbar .group-data .group-by-list {
  border: 1px solid black;
  border-radius: 10px;
  text-align: right;
  background: white;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  color: black;
  font-size: 14px;
  padding: 10px;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 0px 3px 2px #d2d2d2;
}
.context-toolbar .group-data .group-by-list ul {
  padding: 0px !important;
}
.context-toolbar .group-data .group-by-list ul li {
  text-align: left;
  list-style-type: none;
}
.context-toolbar .group-data .group-by-list::-webkit-scrollbar {
  width: 5px !important;
}
.context-toolbar .group-data .group-by-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
.context-toolbar .group-data .group-by-list::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.dt-button {
  float: right;
  padding: 0px !important;
  background: transparent !important;
  border: none !important;
}
.dt-button .dropdown-menu {
  width: 200px;
  padding: 0px;
  top: -25px;
}
.dt-button .dropdown-menu.dropdown-menu-right {
  right: -10px !important;
}
.dt-button .dropdown-menu.dropdown-menu-left {
  left: -10px !important;
}
.dt-button .dropdown-menu .uib-tabset {
  height: 100%;
}
.dt-button .dropdown-menu ul.nav-tabs {
  position: sticky;
  top: 0;
}
.dt-button .dropdown-menu ul.nav-tabs li.uib-tab {
  margin-bottom: 0px;
}
.dt-button .dropdown-menu ul.nav-tabs li.uib-tab.active a {
  background: #eae9e9;
  color: white;
}
.dt-button .dropdown-menu ul.nav-tabs li.uib-tab a {
  color: black;
  border-radius: 0px !important;
  padding: 5px 10px !important;
}
.dt-button .dropdown-menu .tab-content {
  margin-top: 10px;
  color: black !important;
  font-weight: 100;
}
.dt-button .dropdown-menu .tab-content .tab-pane {
  position: relative;
  border: 1px solid lightgray;
  border-radius: 0px !important;
  margin: 0px 5px 5px 5px;
  /* padding: 10px; */
  /* overflow-y: auto; */
}
.dt-button .dropdown-menu .tab-content .tab-pane--heading {
  position: absolute;
  top: -10px;
  background: white;
  font-weight: bold;
  font-size: 14px;
  margin: 0px !important;
  left: 10px;
}
.dt-button .dropdown-menu .tab-content .tab-pane--content {
  margin-top: 10px;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 5px 5px 5px;
}
.dt-button .dropdown-menu .tab-content .tab-pane .clear-filter {
  margin-top: 10px;
  position: absolute;
  bottom: 10px;
}
.dt-button .dropdown-menu .tab-content .tab-pane li.group-active {
  background: #ecf0f1;
}
.dt-button .dropdown-menu .tab-content .tab-pane li a {
  color: black;
  font-weight: 100;
  font-size: 12px;
}
.dt-button .dropdown-menu .tab-content .tab-pane li a:focus,
.dt-button .dropdown-menu .tab-content .tab-pane li a:hover {
  text-decoration: none;
}
.dt-button .dropdown-menu .tab-content .tab-pane li:not(:last-child) {
  margin-bottom: 10px;
}
.dt-button .dropdown-menu .tab-content .tab-pane li:hover {
  background: #ecf0f1;
}
.dt-button .dropdown-toggle {
  display: block;
  position: relative;
  color: white !important;
}
.dt-button .dropdown-toggle .fa-toggle-down {
  font-size: 16px;
}
.tab-pane--content::-webkit-scrollbar {
  width: 5px !important;
  height: 10px;
  border-radius: 10px;
}
.tab-pane--content::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
.dataTables_filter {
  float: right;
  padding: 2px 0px 2px 0px;
}
.dataTables_filter label {
  margin-bottom: 0px !important;
}
.dataTables_filter label input {
  height: 25px !important;
}
.input-radio {
  position: relative;
  top: 2px;
  margin: 0px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.highcharts-container .label {
  z-index: 1 !important;
}
.highcharts-tooltip span {
  opacity: 1;
  z-index: 999 !important;
}
.scale-0 {
  transform: scale(1) !important;
}
.cell-text-icon-center {
  display: flex;
  justify-content: center;
}
.cell-text-icon-center .grid-action-btn {
  position: relative;
  padding-left: 5px;
}
/*
* Customized GTW Angular DataTables styles with rounded corner and box-shadow
* Note: To properly style your datatable, apply rounded-shadow-datatable in the class list, and start your table element id with
*       rounded-shadow-datatable, i.e. <table id="rounded-shadow-datatable-example" class="display rounded-shadow-datatable">
*/
.rounded-shadow-datatable {
  width: 100%;
  clear: both;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 7px;
  box-shadow: 2px 2px 4px #808080, -2px -2px 6px #dadada;
}
.rounded-shadow-datatable thead th {
  background-color: #cd5763;
  color: white;
}
.rounded-shadow-datatable thead th:first-child {
  border-top-left-radius: 7px;
}
.rounded-shadow-datatable thead th:last-child {
  border-top-right-radius: 7px;
}
.rounded-shadow-datatable thead th,
.rounded-shadow-datatable tfoot th {
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}
.rounded-shadow-datatable thead th,
.rounded-shadow-datatable thead td {
  padding: 10px 18px;
}
.rounded-shadow-datatable thead th:active,
.rounded-shadow-datatable thead td:active {
  outline: none;
}
.rounded-shadow-datatable tfoot th,
.rounded-shadow-datatable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111111;
}
.rounded-shadow-datatable thead .sorting,
.rounded-shadow-datatable thead .sorting_asc,
.rounded-shadow-datatable thead .sorting_desc,
.rounded-shadow-datatable thead .sorting_asc_disabled,
.rounded-shadow-datatable thead .sorting_desc_disabled {
  cursor: pointer;
  *cursor: hand;
  background-repeat: no-repeat;
  background-position: center right;
}
.rounded-shadow-datatable tbody tr {
  background-color: white;
}
.rounded-shadow-datatable tbody tr.selected {
  background-color: #b0bed9;
}
.rounded-shadow-datatable tbody th,
.rounded-shadow-datatable tbody td {
  padding: 8px 10px;
}
.rounded-shadow-datatable tbody tr:last-child td:first-child {
  border-bottom-left-radius: 7px;
}
.rounded-shadow-datatable tbody tr:last-child td:last-child {
  border-bottom-right-radius: 7px;
}
.rounded-shadow-datatable.row-border tbody th,
.rounded-shadow-datatable.row-border tbody td,
.rounded-shadow-datatable.display tbody th,
.rounded-shadow-datatable.display tbody td {
  border-top: 1px solid #dddddd;
}
.rounded-shadow-datatable.row-border tbody tr:first-child th,
.rounded-shadow-datatable.row-border tbody tr:first-child td,
.rounded-shadow-datatable.display tbody tr:first-child th,
.rounded-shadow-datatable.display tbody tr:first-child td {
  border-top: none;
}
.rounded-shadow-datatable.cell-border tbody th,
.rounded-shadow-datatable.cell-border tbody td {
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.rounded-shadow-datatable.cell-border tbody tr th:first-child,
.rounded-shadow-datatable.cell-border tbody tr td:first-child {
  border-left: 1px solid #dddddd;
}
.rounded-shadow-datatable.cell-border tbody tr:first-child th,
.rounded-shadow-datatable.cell-border tbody tr:first-child td {
  border-top: none;
}
.rounded-shadow-datatable.stripe tbody tr.odd,
.rounded-shadow-datatable.display tbody tr.odd {
  background-color: #f9f9f9;
}
.rounded-shadow-datatable.stripe tbody tr.odd.selected,
.rounded-shadow-datatable.display tbody tr.odd.selected {
  background-color: #abb9d3;
}
.rounded-shadow-datatable.hover tbody tr:hover,
.rounded-shadow-datatable.display tbody tr:hover {
  background-color: whitesmoke;
}
.rounded-shadow-datatable.hover tbody tr:hover.selected,
.rounded-shadow-datatable.display tbody tr:hover.selected {
  background-color: #a9b7d1;
}
.rounded-shadow-datatable.order-column tbody tr > .sorting_1,
.rounded-shadow-datatable.order-column tbody tr > .sorting_2,
.rounded-shadow-datatable.order-column tbody tr > .sorting_3,
.rounded-shadow-datatable.display tbody tr > .sorting_1,
.rounded-shadow-datatable.display tbody tr > .sorting_2,
.rounded-shadow-datatable.display tbody tr > .sorting_3 {
  background-color: #f9f9f9;
}
.rounded-shadow-datatable.order-column tbody tr.selected > .sorting_1,
.rounded-shadow-datatable.order-column tbody tr.selected > .sorting_2,
.rounded-shadow-datatable.order-column tbody tr.selected > .sorting_3,
.rounded-shadow-datatable.display tbody tr.selected > .sorting_1,
.rounded-shadow-datatable.display tbody tr.selected > .sorting_2,
.rounded-shadow-datatable.display tbody tr.selected > .sorting_3 {
  background-color: #acbad4;
}
.rounded-shadow-datatable.display tbody tr.odd > .sorting_1,
.rounded-shadow-datatable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #f1f1f1;
}
.rounded-shadow-datatable.display tbody tr.odd > .sorting_2,
.rounded-shadow-datatable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: #f3f3f3;
}
.rounded-shadow-datatable.display tbody tr.odd > .sorting_3,
.rounded-shadow-datatable.order-column.stripe tbody tr.odd > .sorting_3 {
  background-color: whitesmoke;
}
.rounded-shadow-datatable.display tbody tr.odd.selected > .sorting_1,
.rounded-shadow-datatable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background-color: #a6b3cd;
}
.rounded-shadow-datatable.display tbody tr.odd.selected > .sorting_2,
.rounded-shadow-datatable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
  background-color: #a7b5ce;
}
.rounded-shadow-datatable.display tbody tr.odd.selected > .sorting_3,
.rounded-shadow-datatable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
  background-color: #a9b6d0;
}
.rounded-shadow-datatable.display tbody tr.even > .sorting_1,
.rounded-shadow-datatable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #f9f9f9;
}
.rounded-shadow-datatable.display tbody tr.even > .sorting_2,
.rounded-shadow-datatable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: #fbfbfb;
}
.rounded-shadow-datatable.display tbody tr.even > .sorting_3,
.rounded-shadow-datatable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: #fdfdfd;
}
.rounded-shadow-datatable.display tbody tr.even.selected > .sorting_1,
.rounded-shadow-datatable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background-color: #acbad4;
}
.rounded-shadow-datatable.display tbody tr.even.selected > .sorting_2,
.rounded-shadow-datatable.order-column.stripe tbody tr.even.selected > .sorting_2 {
  background-color: #adbbd6;
}
.rounded-shadow-datatable.display tbody tr.even.selected > .sorting_3,
.rounded-shadow-datatable.order-column.stripe tbody tr.even.selected > .sorting_3 {
  background-color: #afbdd8;
}
.rounded-shadow-datatable.display tbody tr:hover > .sorting_1,
.rounded-shadow-datatable.order-column.hover tbody tr:hover > .sorting_1 {
  background-color: #eaeaea;
}
.rounded-shadow-datatable.display tbody tr:hover > .sorting_2,
.rounded-shadow-datatable.order-column.hover tbody tr:hover > .sorting_2 {
  background-color: #ebebeb;
}
.rounded-shadow-datatable.display tbody tr:hover > .sorting_3,
.rounded-shadow-datatable.order-column.hover tbody tr:hover > .sorting_3 {
  background-color: #eeeeee;
}
.rounded-shadow-datatable.display tbody tr:hover.selected > .sorting_1,
.rounded-shadow-datatable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  background-color: #a1aec7;
}
.rounded-shadow-datatable.display tbody tr:hover.selected > .sorting_2,
.rounded-shadow-datatable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  background-color: #a2afc8;
}
.rounded-shadow-datatable.display tbody tr:hover.selected > .sorting_3,
.rounded-shadow-datatable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  background-color: #a4b2cb;
}
.rounded-shadow-datatable.no-footer {
  border: none;
}
.rounded-shadow-datatable.nowrap th,
.rounded-shadow-datatable.nowrap td {
  white-space: nowrap;
}
.rounded-shadow-datatable.compact thead th,
.rounded-shadow-datatable.compact thead td {
  padding: 4px 17px 4px 4px;
}
.rounded-shadow-datatable.compact tfoot th,
.rounded-shadow-datatable.compact tfoot td {
  padding: 4px;
}
.rounded-shadow-datatable.compact tbody th,
.rounded-shadow-datatable.compact tbody td {
  padding: 4px;
}
.rounded-shadow-datatable th.dt-left,
.rounded-shadow-datatable td.dt-left {
  text-align: left;
}
.rounded-shadow-datatable th.dt-center,
.rounded-shadow-datatable td.dt-center,
.rounded-shadow-datatable td.dataTables_empty {
  text-align: center;
}
.rounded-shadow-datatable th.dt-right,
.rounded-shadow-datatable td.dt-right {
  text-align: right;
}
.rounded-shadow-datatable th.dt-justify,
.rounded-shadow-datatable td.dt-justify {
  text-align: justify;
}
.rounded-shadow-datatable th.dt-nowrap,
.rounded-shadow-datatable td.dt-nowrap {
  white-space: nowrap;
}
.rounded-shadow-datatable thead th.dt-head-left,
.rounded-shadow-datatable thead td.dt-head-left,
.rounded-shadow-datatable tfoot th.dt-head-left,
.rounded-shadow-datatable tfoot td.dt-head-left {
  text-align: left;
}
.rounded-shadow-datatable thead th.dt-head-center,
.rounded-shadow-datatable thead td.dt-head-center,
.rounded-shadow-datatable tfoot th.dt-head-center,
.rounded-shadow-datatable tfoot td.dt-head-center {
  text-align: center;
}
.rounded-shadow-datatable thead th.dt-head-right,
.rounded-shadow-datatable thead td.dt-head-right,
.rounded-shadow-datatable tfoot th.dt-head-right,
.rounded-shadow-datatable tfoot td.dt-head-right {
  text-align: right;
}
.rounded-shadow-datatable thead th.dt-head-justify,
.rounded-shadow-datatable thead td.dt-head-justify,
.rounded-shadow-datatable tfoot th.dt-head-justify,
.rounded-shadow-datatable tfoot td.dt-head-justify {
  text-align: justify;
}
.rounded-shadow-datatable thead th.dt-head-nowrap,
.rounded-shadow-datatable thead td.dt-head-nowrap,
.rounded-shadow-datatable tfoot th.dt-head-nowrap,
.rounded-shadow-datatable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}
.rounded-shadow-datatable tbody th.dt-body-left,
.rounded-shadow-datatable tbody td.dt-body-left {
  text-align: left;
}
.rounded-shadow-datatable tbody th.dt-body-center,
.rounded-shadow-datatable tbody td.dt-body-center {
  text-align: center;
}
.rounded-shadow-datatable tbody th.dt-body-right,
.rounded-shadow-datatable tbody td.dt-body-right {
  text-align: right;
}
.rounded-shadow-datatable tbody th.dt-body-justify,
.rounded-shadow-datatable tbody td.dt-body-justify {
  text-align: justify;
}
.rounded-shadow-datatable tbody th.dt-body-nowrap,
.rounded-shadow-datatable tbody td.dt-body-nowrap {
  white-space: nowrap;
}
.rounded-shadow-datatable,
.rounded-shadow-datatable th,
.rounded-shadow-datatable td {
  box-sizing: content-box;
}
/*
 * Control feature layout
 */
div[id^='rounded-shadow-datatable'].dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_length {
  float: left;
  padding-top: 2px;
  padding-bottom: 5px;
  margin: 0;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_length label {
  margin-bottom: 0;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_length select {
  border: 1px solid #E4E4E4;
  border-radius: 4px;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
  padding-top: 2px;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
  margin-bottom: 0;
  border-radius: 4px;
  height: 30px !important;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_filter input:focus {
  /*border-color: #cd5763;*/
  /*outline: 0;*/
  /*box-shadow: 0 0 8px #cd5763;*/
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
  margin-left: 0;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  /*padding: 0.5em 1em;*/
  padding: 0 !important;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button.current,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333333 !important;
  border: 1px solid #979797;
  background-color: white;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, gainsboro));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, white 0%, gainsboro 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, white 0%, gainsboro 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, white 0%, gainsboro 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, white 0%, gainsboro 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, white 0%, gainsboro 100%);
  /* W3C */
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: transparent;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button a {
  outline: none;
  border: none;
  border-radius: 15px;
  color: #333333;
  background-color: transparent;
  transition: background-color 0.3s ease;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button a:hover {
  background-color: whitesmoke;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button.disabled a {
  color: #cccccc;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button.disabled a:hover {
  background-color: transparent;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate .paginate_button.active a {
  background-color: #cd5763;
  color: white;
  filter: drop-shadow(0.1rem 0.1rem 0.3rem crimson);
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_length,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_filter,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_info,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_processing,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate {
  color: #333333;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_filter {
  margin-right: 0;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_scroll {
  clear: both;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
  vertical-align: middle;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #111111;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .no-footer div.dataTables_scrollHead #gold-sync-table,
div[id^='rounded-shadow-datatable'].dataTables_wrapper .no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper::after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}
@media screen and (max-width: 767px) {
  div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_info,
  div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }
  div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}
@media screen and (max-width: 640px) {
  div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_length,
  div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }
  div[id^='rounded-shadow-datatable'].dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}
div[id^='rounded-shadow-datatable'].dataTables_wrapper .mb-0 {
  margin-bottom: 0 !important;
}
table[id^='rounded-shadow-datatable'] {
  margin: 5px auto !important;
}
.dashboard-container.theme-black {
  background-color: #2e2e2e;
  color: white;
  min-height: calc(100vh - 150px);
}
.dashboard-container.theme-black .gt-dashboard-ex-header {
  background-color: #4f4f52;
}
.dashboard-container.theme-black .gt-dashboard-ex {
  background-color: #343436;
}
.dashboard-container.theme-black .kpi-box {
  background-color: #fff;
}
.dashboard-container.theme-black tr.odd {
  color: white;
  background-color: #4f4f52;
}
.dashboard-container.theme-black tr.shown + tr {
  background-color: #323233;
}
.dashboard-container.theme-black tr.even {
  background-color: #4a4a4b;
  color: white;
}
.dashboard-container.theme-black .kpi-box p.amount {
  color: cyan;
}
.dashboard-container.theme-black .table-bordered > tbody > tr > td,
.dashboard-container.theme-black .table-bordered > tbody > tr > th,
.dashboard-container.theme-black .table-bordered > tfoot > tr > td,
.dashboard-container.theme-black .table-bordered > tfoot > tr > th,
.dashboard-container.theme-black .table-bordered > thead > tr > td,
.dashboard-container.theme-black .table-bordered > thead > tr > th,
.dashboard-container.theme-black table.dataTable thead td,
.dashboard-container.theme-black table.dataTable thead th,
.dashboard-container.theme-black table.dataTable,
.dashboard-container.theme-black table.dataTable.no-footer {
  border-color: #8c8b8b;
}
.dashboard-container.theme-black table.dataTable thead td,
.dashboard-container.theme-black table.dataTable thead th {
  border-left-color: white;
  border-right-color: white;
}
.dashboard-container.theme-black div.gt-dashboard-ex-body .table tfoot th {
  font-weight: bold;
  background-color: #4a4a4b !important;
  color: white;
}
.dashboard-container.theme-black .data-filters .btn-filter {
  color: white;
  background: #4f4f52;
  background: -moz-linear-gradient(to left, #cd5763 0%, #cd5763 25px, #4f4f52 25px, #4f4f52 100%);
  background: -webkit-linear-gradient(to left, #cd5763 0%, #cd5763 25px, #4f4f52 25px, #4f4f52 100%);
  background: linear-gradient(to left, #cd5763 0%, #cd5763 25px, #4f4f52 25px, #4f4f52 100%);
}
.modal-content .theme-black {
  background-color: #2e2e2e;
  color: white;
}
.modal-content .theme-black .panel-body {
  background-color: #343436;
  color: white;
  border-color: #8c8b8b !important;
}
.modal-content .theme-black .panel-default,
.modal-content .theme-black .modal-footer {
  border-color: #8c8b8b !important;
}
.modal-content .theme-black .kpi-box p.amount {
  color: cyan;
}
.modal-content .theme-black .kpi-box {
  background-color: #f5f5f5;
}
.modal-content .theme-black .gt-dashboard-ex-header {
  background-color: #4f4f52;
}
.modal-content .theme-black .gt-dashboard-ex {
  background-color: #343436;
}
.modal-content .theme-black .kpi-box {
  background-color: #f5f5f5;
}
.modal-content .theme-black tr.odd {
  color: white;
  background-color: #4f4f52;
}
.modal-content .theme-black tr.shown + tr {
  background-color: #323233;
}
.modal-content .theme-black tr.even {
  background-color: #4a4a4b !important;
  color: white;
}
.modal-content .theme-black .form-control,
.modal-content .theme-black .panel-default > .panel-heading {
  background-color: #4f4f52 !important;
  color: white !important;
  border-color: #8c8b8b !important;
}
.modal-content .theme-black .dataTables_wrapper .dataTables_filter,
.modal-content .theme-black .dataTables_wrapper .dataTables_info,
.modal-content .theme-black .dataTables_wrapper .dataTables_length,
.modal-content .theme-black .dataTables_wrapper .dataTables_paginate,
.modal-content .theme-black .dataTables_wrapper .dataTables_processing {
  color: white;
}
.default-dropdowns {
  background-color: #313840;
}
.nav-panel {
  background: #1f1f1f;
}
.nav-panel .client-selection {
  background-color: #313840;
}
.nav-panel .nav-header {
  background-color: #313840;
  color: #0d0d0d;
}
.gt-page-header {
  background-color: #22272a;
  color: #aaa;
  border-bottom: 1px solid #454545;
}
.gt-page-body .gg-message-tabs .plus-in-flex .circle {
  background-color: #e45d2a;
  color: #dfdfdf;
}
.gt-page-body .gt-message .gt-message-text {
  color: #dfdfdf;
}
.gt-page-body .gt-message.active,
.gt-page-body .gt-message.active:hover {
  background-color: #2c333b;
}
.gt-message-container .gt-message:hover {
  background-color: #2c333b;
}
div[gt-menu-tree] li[class*="level-"] > div:first-child.selected:not(.screen) {
  border-left: 4px solid #1ca5cf;
}
div[gt-menu-tree] li[class*="level-"] > div:first-child.screen.selected,
div[gt-menu-tree] li[class*="level-"] > div:first-child.screen:hover {
  background-color: #1f6e86 !important;
}
div[gt-menu-search].search-input,
div[gt-select-menu-search].search-input {
  background-color: #5d5f5f;
}
div[gt-menu-search].search-input:hover,
div[gt-select-menu-search].search-input:hover {
  background-color: #d7d7d7;
}
div[data-gt-menu] ul li div i,
div[gt-select-menu-tree] ul li div i {
  color: #5d5f5f;
}
div[data-gt-menu] ul > li[class*="level-"] > div:first-child,
div[gt-select-menu-tree] ul > li[class*="level-"] > div:first-child {
  color: #d7d7d7;
}
div[data-gt-menu] ul > li[class*="level-"] > div:first-child:hover,
div[gt-select-menu-tree] ul > li[class*="level-"] > div:first-child:hover {
  background-color: #4c4f4f;
}
div[data-gt-menu] ul > li[class*="level-"] > div:first-child.screen:not(.screen-space),
div[gt-select-menu-tree] ul > li[class*="level-"] > div:first-child.screen:not(.screen-space) {
  margin-left: 0;
}
.menu-bars {
  background-color: #313840;
}
.menu-bars .menu-bars-box .menu-bars-inner,
.menu-bars .menu-bars-box .menu-bars-inner:before,
.menu-bars .menu-bars-box .menu-bars-inner:after {
  background-color: #fff;
}
.nav-closed .nav-panel {
  background-color: #313840;
  box-shadow: 2px -1px 0px -1px #000000;
}
.header .header-container {
  background-color: #1f1f1f;
}
.header .header-container .gt-global-notifications:hover {
  background-color: #2b2b2b;
}
.header .header-container .logo {
  background: url(assets/img/pwc-logo-white.png) 5% 40% no-repeat;
  background-color: #313840;
}
.header .header-container .app-badge {
  color: #fff;
  background: #cd5763;
  box-shadow: inset 0 0 3px #000;
}
.header .header-container .gt-title-cnt .gt-breadcrumb {
  color: #b6abb0;
}
.header .header-container .gt-title-cnt .gt-breadcrumb ul li i {
  color: #be1c6d;
}
.header .header-container .gt-user-drop-toggle {
  background: #2b2b2b;
}
.header .header-container .gt-user-drop i {
  color: #d7d7d7;
}
.header .header-container .gt-user-drop .header-dropdown-menu {
  border: 1px solid #000;
  background-color: #333;
}
.header .header-container .gt-user-drop .header-dropdown-menu li a {
  color: #dfdfdf;
}
.header .header-container .gt-user-drop .gt-display-name span {
  color: #edeeef;
}
.header .header-container .gt-user-drop .gt-avatar svg circle {
  fill: #cd5763;
}
.header .header-container .gt-header-search {
  border-right: 1px solid #000;
  border-left: 1px solid #000;
}
.header .header-container .gt-header-search:hover {
  background-color: #2b2b2b;
}
.header .header-container .gt-header-search i {
  color: #d7d7d7;
}
.header .header-container .gt-header-nav li {
  color: #d7d7d7;
}
.header .header-container .gt-header-nav li a {
  color: #d7d7d7;
}
.header .gt-global-search .search-box {
  background-color: #000000;
  border-radius: 5px;
}
.gizmo-grid .gg-tools {
  background-color: #4f494d;
}
.gizmo-grid .gg-header {
  background-color: #363738;
  color: #FFFFFF;
}
.gizmo-grid .selected-col-header {
  background-color: #616969 !important;
}
.gizmo-grid .gg-header .gg-header-left-col .endCap {
  background-color: #616969 !important;
}
.sortHeader {
  color: #adaaaa;
}
.div-row .selected-prime-row {
  background-color: #616969;
}
.div-col-prime {
  background-color: #363636;
  color: #dddddd;
  box-shadow: 0 -2px 2px -2px white;
}
.gg-search-holder {
  background-color: #d1d1d1;
  border: 1px solid #717171;
}
.gg-search-holder:hover {
  background-color: #FFFFFF;
}
.gg-search-holder:hover .gg-global-search,
.gg-search-holder .gg-global-search:focus {
  background-color: #FFFFFF;
}
.gg-global-search {
  background-color: #d1d1d1;
  color: #000000;
}
.gizmo-grid .gg-footer .gg-footer-filters {
  background-color: #222021;
}
.gizmo-grid .gg-footer .gg-footer-filters .filter-icon {
  background-color: #333033;
}
.gizmo-grid .edit-col-header {
  background-color: #1f6e86 !important;
}
/* when hiding the picture */
.gt-nav .ng-hide-add {
  animation: 0.4s left-nav-slide-out ease;
}
/* when showing the picture */
.gt-nav .ng-hide-remove {
  animation: 0.4s left-nav-slide-in ease;
}
@keyframes left-nav-slide-in {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  40% {
    opacity: 0.4;
    transform: translateX(-50%);
  }
  50% {
    opacity: 0.5;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes left-nav-slide-out {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  40% {
    opacity: 0.5;
    transform: translateX(-30%);
  }
  50% {
    opacity: 0.4;
    transform: translateX(-50%);
  }
  100% {
    opacity: 0.8;
    transform: translateX(-100%);
  }
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs {
  background-color: #22272a;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs .cont-wrapper {
  border-right: 1px solid #4c4f4f;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li {
  color: #f5f5f5;
  padding-top: 4px;
  line-height: 32px;
  background-color: #22272a;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li .fa-circle {
  color: #6c6c6c;
}
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li:hover,
.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li.active {
  color: #ffb600;
  padding-top: 0px;
  background-color: #131417;
  border-bottom: 2px solid #e45c2b;
}
.ui-tabs-scrollable > .nav-button.left-nav-button,
.ui-tabs-scrollable.show-drop-down > .nav-button.right-nav-button,
.ui-tabs-scrollable > .dropdown > .dropdown-toggle {
  background-color: #293436;
  color: #aaa;
  border-right: 1px solid #4c4f4f;
}
.gt-filter-container {
  background-color: #22272a;
  border-top: 1px solid #4c4f4f;
}
.gt-filter-container i.gt-refresh {
  color: #aaa;
}
.gt-filter-container > input,
.gt-filter-container .gt-filter-loading,
.gt-filter-container select,
.gt-hierarchy-drop-down .drop-down-btn,
.gt-filter-container .input-group > input {
  background-color: #293436;
  border: 1px solid #4c4f4f;
  color: #aaa;
}
.gt-hierarchy-drop-down .drop-down-btn .badge,
.gt-hierarchy-drop-down .gt-drop-container .left-menu li .badge {
  background-color: #e45c2b;
}
.df-select .df-gt-lazy-load-drp-btn {
  background-color: #293436;
  border: 1px solid #4c4f4f;
  color: #aaa;
}
.df-select .df-gt-lazy-load-list-trunc:hover {
  background-color: #474747;
}
.df-select .df-gt-lazy-dropdown-list {
  background-color: #202020;
  color: #cbc8c8;
  border: 1px solid #4c4f4f;
}
.df-select .df-gt-lazy-dropdown-list .hdr {
  background-color: #111111;
}
.df-select .df-gt-lazy-dropdown-list .sticky-search-bar input {
  background-color: #6c6c6c;
  border: 1px solid #878787;
}
.df-select .df-gt-lazy-dropdown-list .list-ul {
  background-color: #303030;
}
.videoPanel .panel {
  background-color: #333;
}
.import-container {
  background-color: #22272a;
  border-top: 1px solid #454545;
}
